
.advance-tab-button-1 .tab-button-list {
    list-style: none;
    padding: 0;
    margin: -10px 0;
  }
  
  img.error-logo {
    width: 100px;
}
.myleadform {
    background: #fff;
    padding: 30px 30px 30px 30px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 8px 9px 35px 0px rgb(145 145 145 / 27%);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
  div#talored {
    margin-bottom: 40px;
}
.bnr-video video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    margin-top: 30px;
}
span.Now {
    color: #fff;
    font-size: 18px;
    padding-right: 10px;
}

.loader{
    position: absolute;
    left: 10%;
    top :10%;
}
.calendly-inline-widget {
    min-width: 500px !important;
}
.mynewform {
    text-align: left;
}
select#medicalconditions {
    margin-bottom: 20px;
}
.react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    width: 100%;
}
input.submit {
    background: #d20022 !important;
    color: #fff !important;
    padding: 12px 35px !important;
    float: left !important;
    height: unset !important;
    border: unset !important;
    font-weight: 700 !important;
}
button.previous {
    background: #201d1d;
    color: #fff;
    margin-right: 8px;
    padding: 12px 35px;
}
button.next {
    background: #d20022;
    color: #fff;
      padding: 12px 35px;
      float: left;
}

ul.formlisted {
    list-style: none;
    display: inline-flex;
    padding: 10px 28px;
    float: left;
    padding-left: 0px;
}
@media (min-width: 1400px) {
    .container {
      max-width: 1400px !important;
    }
  }
  @media (min-width: 1920px) {
    .container {
      max-width: 1400px;
    }
  }
img.phonecall {
    margin-right: 10px;
    width: 25px;
}
span.redspn {
    color: #c9002c;
    font-weight: 900;
}
.bnrwhitebox2.text-centerinput.next.action-button {
    width: 140px;
    background: #D20021;
    font-weight: bold;
    margin-top: 35px !important;
    color: #fff;
    border: 0 none;
    text-transform: uppercase;
    border-radius: 2px;
    cursor: pointer;
    padding: 10px 5px;
    margin: auto;
}
.bnrwhitebox2.text-center  #msform .action-button-previous {
    width: 140px;
    margin-top: 14px !important;
    font-weight: bold;
    background: #201d1d;
    color: white;
    border: 0 none;
    font-size: 14px;
    border-radius: 0px;
    cursor: pointer;
    padding: 10px 5px;
    float: left;
    position: relative;

    left: 48%;
    margin: auto;
}
.bnrwhitebox2.text-center input#submit {
    width: 140px;
    background: #D20021;
    font-weight: bold;
    color: #fff;
    border: 0 none;
    text-transform: uppercase;
    border-radius: 2px;
    cursor: pointer;
    padding: 10px 5px;
    float: right;
    margin-top: 13px;
}
section.myhome.bg-overlay2::before {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    content: "";
    background-color: transparent;
    background-image: linear-gradient(90deg,#f7f8fb 50%,#FFFFFF00 90%);
    opacity: .9;
    transition: background .3s,border-radius .3s,opacity .3s;
}
section.myhome.bg-overlay2 {
    background-image: url(../images//insurance/happy.webp);
    padding: 163px 0px 64px 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    position: relative;
}

.bnrwhitebox2.text-center h3 {
    font-size: 30px;
    font-weight: 800;
    text-transform: capitalize;
}
.bnrwhitebox2.text-center #msform fieldset {
    background: transparent;}
.bnrwhitebox2.text-center {
    padding: 30px 30px 10px 30px;
    background: #fffc;
    text-align: center;
    border-radius: 8px 8px 0 0;
    position: relative;
    padding-bottom: 80px;
}
.popular-post-widget-img {
    float: left;
    margin-right: 20px;
}
section.thankyou {
    padding-top: 150px;
    padding-bottom: 60px;
}
.thankyoubox {
    margin: 0 auto;
    text-align: center;
    border: 1px solid;
    border-radius: 10px;
    width: 80%;
    padding: 50px;
}
img.thankyou {
    max-width: 200px;
    margin-bottom: 40px;
}
section.thankyou h3 {
    font-size: 26px;
    text-transform: capitalize;
    font-weight: 800;
    margin-bottom: 10px;
}
.footer-address-icon {
    float: left;
    margin-right: 15px;
}
.footer-address ul {
    padding-left: 0px;
    list-style: none;
}
section.contactdetail form input.form-rol {
    margin-top: 14px;
}
section.contactdetail ul {
    padding-left: 0px;
}
.encompass .Management a {
    color: #fff;
}
.section-bg-7 {
    background-color: #3c3838;
}
.footer-menu ul li {
    list-style: none;
    margin-top: 0px;
    margin-bottom: 10px;
    position: relative;
    font-size: 16px;
    line-height: 26px;
    color: #e8e8e8;
}
.footer-newsletter .btn-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    height: 100%;
}
.footer-newsletter form {
    position: relative;
}
.footer-newsletter .btn-wrapper .btn {
    padding: 0 18px;
    height: 100%;
}
.ltn__copyright-design.clearfix p {
    font-size: 14px;
    padding-top: 15px;
    line-height: 26px;
    color: #e8e8e8;
}
.ltn__copyright-design.clearfix a {
    font-size: 14px;
    padding-top: 15px;
    line-height: 26px;
    color: #e8e8e8;
}
button.react-time-picker__clear-button.react-time-picker__button {
    display: none !important;
}
button.react-time-picker__clock-button.react-time-picker__button {
    display: none !important;
}
select.react-time-picker__inputGroup__input.react-time-picker__inputGroup__amPm {
    width: 81px !important;
    height: 48px !important;
}
input.react-time-picker__inputGroup__input.react-time-picker__inputGroup__hour {
    width: 80px !important;
}
input.react-time-picker__inputGroup__input.react-time-picker__inputGroup__minute {
    width: 80px !important;
}
span.react-time-picker__inputGroup__leadingZero {
    display: none !important;
}
select option[value=""] { 
    display: none; 
} 
label.cdtfc {
	background: #D20022;
	color: white;
	font-weight: bold;
	text-align: center;
	font-size: 19px;
	width: 80%;
	height: 41px;
	padding-top: 6px;
	/* border-radius: 21px; */
	margin: 0 auto;
	margin-bottom: 11px;
}
.footer-menu ul {
padding-left: 0px;
}
.footer-top.dark-view.padding-tb {
    padding-top: 92px;
    padding-bottom: 50px;
}
.ltn__social-media ul li {
    list-style: none;
    display: inline-block;
    margin: 0 15px 0 0;
}
.footer-widget .footer-title {
    font-size: 22px;
    font-family: 'Mulish', sans-serif !important;
    margin-bottom: 25px;
}
img.logo-2 {
    width: 30%;
    margin-bottom: 17px;
}
ul#progressbar {
    display: none;
}
.progress {
    display: none !important;
}
#msform fieldset:not(:first-of-type) {
    display: none;
}
.ltn__social-media ul {
    padding-left: 0px;
}

    #heading {
        text-transform: uppercase;
        color: #D20021;
        font-weight: normal
    }

    #msform {
        text-align: center;
        position: relative;
        margin-top: 20px
    }

    #msform fieldset {
        background: white;
        border: 0 none;
        border-radius: 0.5rem;
        box-sizing: border-box;
        width: 100%;
        margin: 0;
        padding-bottom: 20px;
        position: relative
    }

    .form-card {
        text-align: left
    }

    #msform fieldset:not(:first-of-type) {
        display: none
    }

    #msform input,
    #msform textarea {
        padding: 8px 15px 8px 15px;
        border: 1px solid #ccc;
        border-radius: 0px;
        margin-bottom: 25px;
        margin-top: 2px;
        width: 100%;
        box-sizing: border-box;
        font-family: montserrat;
        color: #2C3E50;
        background-color: #ECEFF1;
        font-size: 16px;
        letter-spacing: 1px
    }

    #msform input:focus,
    #msform textarea:focus {
        -moz-box-shadow: none !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        border: 1px solid #201d1d;
        outline-width: 0
    }

    #msform .action-button {
        width: 140px;
        background: #D20021;
        font-weight: bold;
        color: white;
        border: 0 none;
        border-radius: 0px;
        cursor: pointer;
        padding: 10px 5px;
       float: right;
       margin-top: 14px;
    }

    #msform .action-button:hover,
    #msform .action-button:focus {
        background-color: #201d1d
    }

    #msform .action-button-previous {
        width: 100px;
        background: #616161;
        font-weight: bold;
        color: white;
        border: 0 none;
        border-radius: 0px;
        cursor: pointer;
        padding: 10px 5px;
        margin: 10px 5px 10px 0px;
        float: right
    }

    #msform .action-button-previous:hover,
    #msform .action-button-previous:focus {
        background-color: #000000
    }

    .card {
        z-index: 0;
        border: none;
        position: relative
    }

    .fs-title {
        font-size: 25px;
        color: #D20021;
        margin-bottom: 15px;
        font-weight: normal;
        text-align: left
    }

    .purple-text {
        color: #D20021;
        font-weight: normal
    }

    .steps {
        font-size: 25px;
        color: gray;
        margin-bottom: 10px;
        font-weight: normal;
        text-align: right
    }

    .fieldlabels {
        color: gray;
        text-align: left
    }

    #progressbar {
        margin-bottom: 30px;
        overflow: hidden;
        color: lightgrey
    }

    #progressbar .active {
        color: #D20021
    }

    #progressbar li {
        list-style-type: none;
        font-size: 15px;
        width: 25%;
        float: left;
        position: relative;
        font-weight: 400
    }

    #progressbar #account:before {
        font-family: FontAwesome;
        content: "\f13e"
    }

    #progressbar #personal:before {
        font-family: FontAwesome;
        content: "\f007"
    }

    #progressbar #payment:before {
        font-family: FontAwesome;
        content: "\f030"
    }

    #progressbar #confirm:before {
        font-family: FontAwesome;
        content: "\f00c"
    }

    #progressbar li:before {
        width: 50px;
        height: 50px;
        line-height: 45px;
        display: block;
        font-size: 20px;
        color: #ffffff;
        background: lightgray;
        border-radius: 50%;
        margin: 0 auto 10px auto;
        padding: 2px
    }

    #progressbar li:after {
        content: '';
        width: 100%;
        height: 2px;
        background: lightgray;
        position: absolute;
        left: 0;
        top: 25px;
        z-index: -1
    }

    #progressbar li.active:before,
    #progressbar li.active:after {
        background: #D20021
    }

    .progress {
        height: 20px
    }

    .progress-bar {
        background-color: #D20021
    }

    .fit-image {
        width: 100%;
        object-fit: cover
    }
div#botmservce .container {
    padding: 0px;
}
h3.nav-info-box-title {
    margin: 0 0 5px;
    padding: 0;
    color: #fff;
    font-family: "Rubik",Sans-serif;
    font-size: 16px;
    font-weight: 400;
    text-transform: none;
    line-height: 22px;
    letter-spacing: -.15px;
}
a.theme-btn-1.btn.btn-effect-1 {
    position: relative;
    border-radius: 5px;
    display: inline-block;
    font-size: 16px;
    color: #fff;
    font-weight: 400;
    font-family: var(--ltn__heading-font);
    padding: 14px 30px;
    text-align: center;
    cursor: pointer;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    position: relative;
    z-index: 1;
    margin-right: 15px;
}
img.patrtner1 {
    width: 200px;
}
section.red {
    background: #7a7878;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: -85px;
}
section.Newleadform h2 {
    margin-bottom: 30px;
    text-transform: capitalize;
}
section.policytainer .headercnt {
    background-color: transparent;
    padding: 18px 30px;
    border-style: solid;
    border-width: 0;
    border: 1px solid #969595;
    border-radius: 0;
    position: relative;
    display: flex;
    border-radius: 5px;
    width: 300px;
    float: right;
    margin-top: 12px;
}
section.policytainer .chatmsg {
    margin: 0 15px 0 0;
    transform: rotate(0deg);
    width: 56px;
    line-height: 54px;
    text-align: center;
    background-color: #4b4b4b;
    border-radius: 50%;
    height: 56px;
}
section.policytainer {
    position: relative;
    border-bottom: 1px solid rgb(0 0 0 / 7%);
}
section.policytainer:after {
    border-radius: 0;
    content: "";
    display: inline-block;
    height: 3px;
    left: 0;
    margin: 0;
    position: absolute;
    vertical-align: bottom;
    width: 50px;
    background-color: #D20021;
}
img.logo {
    width: 76px;
    padding-top: 10px;
    padding-bottom: 20px;
}
div#leftneed {
    padding-left: 32px;
}
section.newwhychoose {
    padding-top: 80px;
}
div#rightneed {
    padding-right: 32px;
}
.Needs-box {
    margin-bottom: 41px;
}
div#Experienced {
    margin-top: 53px;
}
section.Newleadform label {
    color: #000;
    margin-top: 10px;
}
section.Newleadform .mainform {
    padding: 30px;
    margin: 0 auto;
    display: block;
    text-align: center;
    background-color: #fff;
}
span.newhy {
    font-weight: 300;
}
section.newlife section.Newleadform {
    padding-top: 50px;
}
section.Newleadform .radiogroup label {
    cursor: pointer;
    display: inline-block;
    margin: 5px;
    border: 1px solid;
    background: #fff;
    height: 67px;
    padding: 5px;
    line-height: 30px;
    width: 208px;
    text-align: center;
    border-radius: 4px;
}
section.newlife #msform fieldset {
    background: transparent;
}
section.newlife .cirlegroup span.d-inline-block.mr-2 {
    border: 2px solid #444344;
    border-radius: 50%;
    height: 9px;
    width: 9px;
    margin-right: 5px;
}
.newbnr ul {
    margin: 0 auto;
    display: inline-flex;
    padding-left: 0px;
}
section.Newleadform .cirlegroup {
    background-color: #444344;
    border-radius: 10px 10px 0 0;
    padding-left: 10px;
}
.newfomr .divide-heading::after {
    content: "";
    position: absolute;
    width: 100%;
    background-color: rgb(234, 234, 245);
    height: 1px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 18px;
    z-index: -1;
}
.newbnr ul li {
    color: #000;
    font-size: 22px;
    margin-right: 46px;
}
.newfomr .divide-heading span {
    font-size: 16px;
    color: #000;
    font-weight: bold;
    text-align: center;
    background-color: #ffffff;
    padding: 5px 42px;
    width: max-content;
    margin: 0 auto;
    display: block;
}
.newfomr .divide-heading {
    position: relative;
    z-index: 1;
    width: 500px;
    margin: 0 auto;
}
.newfomr {
    margin-top: 30px;
    border-radius: 10px;
}
section.newlife {
    background-color: #efefef;
    background-image: url(../images/insurance/yellowbg.svg);
    background-position: 0 100%;
    background-repeat: no-repeat;
    background-size: auto 738px;
    padding: 150px 40px 80px 40px;
    display: block;
    position: relative;
    
}
.newbnr h2 {
    font-size: 60px;
    line-height: 64px;
}
img.homeinsurance {
    width: 73%;
}
span.Massive {
    font-weight: 300;
    font-size: 41px;
}
.site-logo.go-top img {
    width: 28%;
}
.footer-logo img {
    width: 30%;
}
.radiogroup label {
    display: inline-block;
    margin: 5px;
    border: 1px solid;
    height: 69px;
    padding: 5px;
    line-height: 30px;
    width: 88px;
    text-align: center;
    border-radius: 4px;
}
p.Gener {
    color: #000;
    margin-top: 20px;
    font-size: 19px;
    font-weight: 700;
    margin-bottom: 5px;
}
.Newleadform-outer-box #msform .action-button {
    width: 140px;
    background: #D20021;
    font-weight: bold;
    margin-top: 35px !important;
    color: #fff;
    border: 0 none;
    text-transform: uppercase;
    border-radius: 2px;
    cursor: pointer;
    padding: 10px 5px;
    margin: auto;
}
section.Newleadform .Newleadform-outer-box .mainform .mynewform form#msform input#submit{
    width: 140px;
    background: #D20021;
    font-weight: bold;
    color: #fff;
    border: 0 none;
    text-transform: uppercase;
    border-radius: 2px;
    cursor: pointer;
    padding: 10px 5px;
  float: right;
  margin-top: 35px;
}
section.Newleadform .nice-select {
   border-radius: 2px;
  line-height: 50px;
    margin-top: 8px;
    height: 44px;
    line-height: 43px;
   
}
section.Newleadform #msform input {
    padding: 8px 15px 8px 15px;
    border: 1px solid #ccc;
    border-radius: 0px;
    margin-bottom: 10px;
    margin-top: 0px;
    width: 100%;
    height: 46px;
    border-radius: 2px;
    box-sizing: border-box;
    font-family: montserrat;
    color: #2C3E50;
    background-color: #fff;
    font-size: 14px;
    letter-spacing: 0px;
}
section.Newleadform #msform .action-button-previous {
    width: 140px;
    margin-top: 35px !important;
    font-weight: bold;
    background: #999896;
    color: white;
    border: 0 none;
    border-radius: 2px;
    border-radius: 0px;
    cursor: pointer;
    padding: 10px 5px;
    margin: 10px 5px 10px 0px;
    float: left;
    position: relative;
    text-transform: uppercase;
    left: 48%;
    margin: auto;
}
form#msform .radiogroup label span input {
    padding: 8px 15px 8px 15px;
    border: 1px solid #ccc;
    border-radius: 0px;
    margin-bottom: 25px;
    margin-top: 2px;
    width: 80%;
    height: 17px;
    border-radius: 5px;
    box-sizing: border-box;
    font-family: montserrat;
    color: #2C3E50;
    background-color: #fff;
    font-size: 16px;
    letter-spacing: 1px;
}
img.cont {
    width: 71%;
    margin-right: 80px;
    padding-top: 40px;
}
.mainform {
   padding: 80px;
    margin: 0 auto;
    display: block;
    text-align: center;
}
.cirlegroup {
    background-color: #e5e5e5;
    border-radius: 10px 10px 0 0;
}
.cirlegroup span.d-inline-block.mr-2 {
    border: 2px solid #4a4a4a;
    border-radius: 50%;
    height: 9px;
    width: 9px;
    margin-right: 5px;
}
.Newleadform-outer-box {
    border: 1px solid #383737;
    border-bottom: 0;
    border-radius: 10px 10px 0 0;
}
section.formcontact {
    padding-bottom: 80px;
    padding-top: 20px;
}
section.leadbottom .row {
    background: #4b4b4b;
    text-align: center;
    padding-top: 10px;
    position: relative;
    margin-bottom: 40px;
}
section.leadbottom i.fa.fa-caret-down {
    font-size: 74px;
    position: absolute;
    left: 0%;
    top: 27px;
    color: #4b4b4b;
}
section.leadbottom h3 {
    color: #fff;
    font-size: 25px;
    margin-bottom: 20px;
}
section.Newleadform {
    padding-top: 150px;
}
section.comparing ul li {
    font-size: 16px;
    color: #000;
    line-height: 40px;
}
section.newcompare {
    padding-top: 80px;
}
.Planningfeatures .financial-box {
    border: 1px solid #c2c2c2;
    border-bottom-left-radius: 10px;
    padding: 20px;
    border-bottom-right-radius: 10px;
    height: 358px;
    margin-bottom: 10px;
    background: #fff;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.find-lead form#msform fieldset .radiogroup input {
    padding: 8px 15px 8px 15px;
    margin-bottom: 0px;
    width: 92%;
    height: 20px;
    border-radius: 5px;
    box-sizing: border-box;
    font-family: montserrat;
    color: #2C3E50;
    background-color: #fff;
    font-size: 16px;
    letter-spacing: 1px;
}
p.Gender {
    color: #fff;
    border-bottom: 1px solid;
    padding-bottom: 20px;
}
.radiogroup {
    margin: 0 auto;
    display: inline-flex;
    text-align: center;
    width: 100%;
    float: none;
    padding-bottom: 14px;
}
section.myhome.bg-overlay .ctabnr {
    margin-left: 100px;
    position: relative;
    position: relative;
}
div#formdiv {
    padding: 3px;
}
section.Financialallleadform label {
    font-size: 15px;
    color: #000;
    margin-bottom: 2px;
}
section.Financialallleadform .fromback form input.form-control {
    background-color: #fff;
    border: 1px solid;
    border-radius: 2px;
    border-color: #9a9c9e;
    height: 41px;
    box-shadow: none;
    padding-left: 20px;
    font-size: 16px;
    color: var(--ltn__paragraph-color);
    width: 100%;
    margin-bottom: 7px;
    padding-right: 40px;
}
section.Financialallleadform .divide-heading span {
     background-color: #efefef;}
section.Financialallleadform {
    padding-top: 170px;
    background-image: url(../images/insurance/greyb.JPG);
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 140px;

}
section.Financialallleadform .divide-heading::after {
    content: "";
    position: absolute;
    width: 100%;
    background-color: rgb(201 0 44);
    height: 1px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 10px;
    z-index: -1;
}
img.finiclapln {
    width: 85%;
 
}
.ltn__blog-meta p {
    margin-bottom: 0px;
}
section.myblogs {
    padding-bottom: 60px;
}
div#form-query {
    padding-bottom: 40px;
}
.calling-dtail {
    background: #fff;
    padding: 30px 20px;
    box-shadow: 8px 9px 35px 0px rgba(145, 145, 145, 0.24);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    height: 250px;
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;
}
div#query-form {
    padding-bottom: 30px;
}
img.paying {
    width: 50px;
}
section.contactpge h2 {
    margin-bottom: 30px;
}
.calling-dtail a {
    font-size: 20px;
}
.fromback {
    margin-left: 80px;
    background: #efefef;
    padding: 30px 50px;
    border: 1px solid #c7c4c4;
}
.fromback form textarea.form-control {
    min-height: 80px;
    background-color: #fff;
    border-color: #9a9c9e;
}
.fromback form input.form-rol {
    width: 170px;
    margin-top: 10px;
    color: #fff;
    margin-bottom: 0px;
    font-family: 'Mulish', sans-serif;
    font-size: 16px;
    height: 56px !important;
    font-weight: 400;
    border: 1px solid #D20021;
    padding: 10px 30px;
    text-align: center;
    cursor: pointer;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    position: relative;
    z-index: 1;
    background-color: #D20021 !important;
    text-transform: uppercase;
}
img.contactus {
    margin-top: 25px;
}
.fromback form input.form-control {
    background-color: #fff;
    border: 1px solid;
    border-radius: 5px;
    border-color: #9a9c9e;
    height: 43px;
    box-shadow: none;
    padding-left: 20px;
    font-size: 16px;
    color: var(--ltn__paragraph-color);
    width: 100%;
    margin-bottom: 7px;
    padding-right: 40px;
}
.calling-dtail .Journey h3 {
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    margin-bottom: 10px;}
section.contactpge {
    background-image: url(../images//insurance//conntctpagebg.webp);
    padding-top: 100px;
    padding-bottom: 100px;
   background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.calling-dtail img.paying {
    width: 40px;
}
section.contactdetail .ltn__social-media-2 ul li a {
    background-color: #efefef;
    color: #5c727d;
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
}
.calling-dtail p {
    font-size: 16px;
    line-height: 26px;
}
section.contactdetail .ltn__social-media-2 ul li a:hover {
    background-color: #D20021;
    color: #fff;
    display: block;
    width: 40px;
    
    height: 40px;
    line-height: 40px;
    text-align: center;
}
section.contactdetail .ltn__widget-title {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 25px;
    text-transform: capitalize;
}
section.contactdetail .ltn__social-media-2 ul li {
    list-style: none;
    display: inline-block;
    margin-right: 24px;
}
section.contactdetail .widget.ltn__social-media-widget {
    background: #fff;
    padding: 30px 20px;
    box-shadow: 8px 9px 35px 0px rgba(145, 145, 145, 0.24);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    height: 250px;
}
.calling-dtail .Journey {
    margin-top: 0px;
}
section.contactdetail {
    padding-top: 100px;
    padding-bottom: 60px;
}
img.newagent {
    width: 16%;
    margin-top: -128px;
    margin-left: 87px;
    float: right;
}
.ltn__blog-img img {
    margin-bottom: 0;
    height: 300px;
}
section.contct-below span.ctabnrtwo a{
    color: #ffda47;
    font-size: 27px;
}
div#PascalCase {
    margin-top: 20px;
}
section.contct-below span.ctabnrtwo a:hover {
    color: #D20021;
    font-size: 27px;
}
section.contct-below span.ctabnrone {
    color: #fff;
    font-size: 30px;
    margin-right: 8px;
}
section.contct-below .ctabnr {
    text-align: left;
}
section.contct-below {
    position: relative;
}
section.Financial .encompass:hover,
section.Financial  .encompass:hover h3,
section.Financial  .encompass:hover a
 {
color: #ffffff !important;
}
section.centersec {
    background-image: url(../images/insurance//waves.png);
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 100px;
}

section.Financial  .encompass:hover h3 {
    background-color: #D20021;
}
section.Financial  .encompass:hover .financial-box{
    border: 1px solid #D20021;  
}
div#financially {
    margin-top: 40px;
  }
  .swiper-Financial-planing {
    margin-top: 60px;
}
.Management{
    background: #6c6c6c;
    padding: 20px 0px;
    color: #fff;
    text-align: center;
    font-size: 22px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-bottom: 0px;
}

.financial-box p {
    margin-bottom: 0px;
}
.financial-box{
  border: 1px solid #201d1d;  
  border-bottom-left-radius: 10px;
  padding: 20px;
  border-bottom-right-radius: 10px;
  height: 263px;
  margin-bottom: 10px;
}
section.Financial {
    padding-top: 80px;
    padding-bottom: 80px;
}
section.Financial h2 {
    margin-bottom: 20px;
}
section.DIfferent-types {
    padding-top: 80px;
    padding-bottom: 80px;
}
.left-side-tb {
    background: #fff;
    padding: 50px;
    border-radius: 10px;
    box-shadow: 8px 9px 35px 0px rgba(145, 145, 145, 0.24);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.advance-tab-button-1 .tab-button-list li {
    margin: 10px 0;
}
section.lifeinsurance-features .react-tabs__tab-list {
    border-bottom: unset;
    margin: 0 0 10px;
    padding: 0;
    margin-bottom: 39px;
}
.at-icon-box {
    position: relative;
    padding: 37px 20px;
    background: #fff;
    border-radius: 10px;
    height: 250px;
    border: 1px solid;
  }
  
  .at-icon-box .at-icon-box-icon {
    margin: 0 0 8px;
    vertical-align: bottom;
  }
  
  .at-icon-box.at-icon-left .at-icon-box-icon {
    float: left;
  }
  .at-icon-box.at-icon-right .at-icon-box-icon {
    float: right;
  }
  .at-icon-box.at-icon-right {
    text-align: right;
  }
  
  .at-icon-box.at-icon-left .at-icon-text,
  .at-icon-box.at-icon-right .at-icon-text {
    overflow: hidden;
  }
  
  .at-icon-box.at-icon-left h3,
  .at-icon-box.at-icon-right h3 {
    font-size: 22px;
    font-weight: 700;
 
  }
  
  .at-icon-box.at-icon-left p,
  .at-icon-box.at-icon-right p {
    font-size: 16px;
    line-height: 26px;
  }
  
  .at-icon-box .at-icon-box-icon i {
    background: #D20021 none repeat scroll 0 0;
    border-radius: 50%;
    color: #fff;
    display: block;
    height: 50px;
    line-height: 50px;
    text-align: center;
    width: 50px;
  }
  
  .at-icon-box .at-icon-box-icon::after {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
  
  .at-icon-box:hover {
    background: #f6f6f6 none repeat scroll 0 0;
  }
  .at-icon-box:hover .at-icon-box-icon {
    padding: 20px 0;
  }
  
  .at-icon-box:hover .at-icon-box-icon::before {
    height: 15px;
  }
  .at-icon-box:hover .at-icon-box-icon::after {
    height: 15px;
  }
  .at-icon-box h3 {
    margin-bottom: 11px;
  }
  .at-icon-box .at-icon-text p:last-child {
    margin: 0;
  }
  .at-icon-box .at-icon-box-icon {
    transition: all 0.3s ease 0s;
  }
  .at-icon-box .at-icon-box-icon i {
    position: relative;
  }
  div#paper {
    padding-top: 40px;
    padding-bottom: 20px;
}
  .at-icon-box .at-icon-box-icon::before {
    background: #ffda47 none repeat scroll 0 0;
    content: "";
    display: block;
    height: 0;
    left: 29px;
    position: absolute;
    top: -3px;
    transition: all 0.3s ease 0s;
    width: 6px;
  }
  .at-icon-box .at-icon-box-icon::after {
    background: #ffda47 none repeat scroll 0 0;
    bottom: -3px;
    content: "";
    display: block;
    height: 0;
    left: 29px;
    position: absolute;
    transition: all 0.3s ease 0s;
    width: 6px;
  }
  
  .at-icon-box .at-icon-box-icon {
    position: relative;
  }
  .at-icon-box .at-icon-box-icon i {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    color: #D20021;
    height: 55px;
    line-height: 55px;
    width: 64px;
  }
  .at-icon-box .at-icon-box-icon i::after {
    content: "";
    font-family: "FontAwesome";
    font-size: 55px;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    left: 12px;
    line-height: 1;
    position: absolute;
    text-transform: none;
    top: 3px;
  }
  
.section.lifeinsurance-features .react-tabs__tab--selected{
    display: inline-block;
    border: 1px solid #696565;
    bottom: -1px;
    position: relative;
    border-radius: 10px;
    background-color: transparent !important;
    font-size: 18px;
    color: #000;
    line-height: 27px;
    font-weight: 700;
    list-style: none;
    margin-right: 10px;
    padding: 26px 12px;
    cursor: pointer;
    width: 225px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../images/insurance/formbg.jpg) !important;
}
section.lifeinsurance-features li {
    display: inline-block;
    border: 1px solid #696565;
    bottom: -1px;
    position: relative;
    border-radius: 10px;
    font-size: 18px;
    color: #000;
    line-height: 27px;
    font-weight: 700;
    list-style: none;
    margin-right: 10px;
    padding: 26px 12px;
    cursor: pointer;
    width: 225px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../images/insurance/formbg.jpg) !important;
}
section.lifeinsurance-features .divide-heading span {
    text-align: center;
    background-color: #D20021;
    padding: 0 20px;
    width: max-content;
    margin: 0 auto;
    display: block;
}
.divide-heading h2 {
    color: #fff;
    padding: 10px;
}
section.comparing {
    padding-top: 30px;
}
section.Financialplanning {
    padding-top: 180px;
    padding-bottom: 80px;
    background-image: url(../images//insurance/greylines.png);
    background-position: 0 100%;
    background-repeat: repeat;
}
section.lifeinsurance-features .divide-heading::after {
    content: "";
    position: absolute;
    width: 100%;
    background-color: #D20021;
    height: 1px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 30px;
    z-index: -1;
}
.advance-tab-button-1 .tab-button-list .tab-button {
    background: #fff;
    border: 0.094rem solid #0f172a14;
    cursor: pointer;
    padding: 15px 27px;
    border-radius: 10px;
    position: relative;
    z-index: 2;
    display: flex;
  }
.advance-tab-button-1 .tab-button-list .react-tabs__tab--selected .tab-button:before {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.advance-tab-button-1 .tab-button-list .tab-button:before {
    position: absolute;
    content: "";
    background: #0f0f11;
    background: var(--color-blackest);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transition: .3s;
    opacity: 0;
    -webkit-transform: scale(.9);
    transform: scale(.9);
    z-index: -1;
    border-radius: 10px;
}
advance-tab-button-1 .tab-button-list .react-tabs__tab--selected .tab-button .title {
    color: #fff;
    color: var(--color-white);
}
.advance-tab-button-1 .tab-button-list .tab-button .description {
    margin-bottom: 0;
}
.advance-tab-button-1 .tab-button-list .react-tabs__tab--selected .tab-button:after{
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.advance-tab-button-1 .tab-button-list .tab-button:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    border-left: 20px solid #0f0f11;
    border-left: 20px solid var(--color-blackest);
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    margin-top: -20px;
    opacity: 0;
    transition: .3s;
    -webkit-transform: scale(.9);
    transform: scale(.9);
}
section.lifeinsurance-features {
    padding-top: 80px;
    padding-bottom: 80px;
    background-image: url(../images//insurance/greylines.png);
    background-position: 0 100%;
    background-repeat: repeat;
}
img.listicon {
    width: 22px;
    margin-right: 8px;
}

section.comparing ul {
    list-style: none;
    padding-left: 0px;
}













section.Allleadform {
    background-image: url(../images/insurance/formbg.jpg);
    display: flex;
    flex-direction: column;
  padding-bottom: 60px;
}
.advance-tab-button-1 .tab-button-list li h4.title {
    font-size: 18px;
    line-height: 27px;
    margin-top: 10px;
}

.divide-heading h2:hover {
    color: #fff;
    padding: 10px;
}
.advance-tab-button-1 .tab-button-list li h4.title:hover{
    font-size: 18px;
    line-height: 27px;
    margin-top: 10px;
    color: #D20021;
}

img.Artboardlife {
    transition: 0.5s;
 margin-bottom: 16px;
}
.find-lead form#msform .nice-select {
    height: 42px;
    border-radius: 2px;
    background-color: #fff;
    margin-bottom: 20px;
}
.react-tabs__tab .react-tabs__tab--selected {
    background-image: linear-gradient(to right, #4755690a, #9d0cb28a, #4343c899, #4755690a);
    width: 50%;
    height: 0.094rem;
    position: absolute;
    content: "";
    bottom: -0.063rem;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0px 0.125rem 0.75rem #4343c84d;
}
.find-lead #msform .action-button-previous {
    width: 140px;
    font-size: 14px !important;
    text-transform: uppercase;
    height: 46px !important;
    font-weight: bold;
    background: #999896;
    color: white;
    border: 0 none;
    border-radius: 5px;
    border-radius: 0px;
    cursor: pointer;
    padding: 10px 5px;
    margin: 10px 5px 10px 0px;
    float: left;
    position: relative;
    left: 40%;
}
section.Allleadform input.next.action-button {
    margin-top: 36px;
    margin-right: 0;
    max-width: 140px;
    position: relative;
    text-transform: uppercase;
    background-color: #ffd744 !important;
    color: #000;
    border-radius: 0px !important;
    display: inline-block;
    font-size: 14px !important;
    font-weight: 400;
    font-family: var(--ltn__heading-font);
    height: 46px !important;
}
.find-lead form#msform input#submit {
    max-width: 140px;
    width: 140px;
    margin-left: 190px;
    font-size: 14px !important;
    height: 46px !important;
    font-weight: bold;
    background: #999896;
    color: #000;
    border: 0 none;
    border-radius: 5px;
    border-radius: 0px;
    cursor: pointer;
    padding: 10px 5px;
    margin-top: 10px;
    position: relative;
    position: relative;
    text-transform: uppercase;
    background-color: #D20021 !important;
    color: var(--white);
}
.find-lead form#msform input.reg-form {
    height: 45px !important;
    border-radius: 5px;
    margin-bottom: 10px;
    font-family: inherit;
    background-color: #f0f0f0;
    font-size: 14px !important;
    letter-spacing: 0px !important;
    font-family: 'Mulish', sans-serif !important;
}

.progress {
    display: none;
}
.find-lead {
    padding: 30px 50px 30px 50px;
    overflow: hidden;
    border-radius: 3px;
    background-color: rgb(118 118 118);
    box-shadow: 0px 3px 95px 0px rgba(0, 0, 0, 0.17);
    width: 100%;
    position: relative;
}
.divide-heading span {
    font-size: 15px;
    color: #3e3f5e;
    font-weight: bold;
    text-align: center;
    background-color: #ffffff;
    padding: 0 20px;
    width: max-content;
    margin: 0 auto;
    display: block;
}
.find-lead form#msform label {
    margin-bottom: 10px;
    color: #fff;
    font-size: 16px;
    display: block;
}
.find-lead form#msform fieldset {
    background: transparent;
}
.find-lead .divide-heading span {
    font-size: 15px;
    color: #fff;
    font-weight: bold;
    text-align: center;
    background-color: #767676;
    padding: 0 20px;
    width: max-content;
    margin: 0 auto;
    display: block;
}
h2.coverage {
    font-size: 35px;
    text-align: center;
    margin-bottom: 14px;
    color: #fff;
}
.divide-heading::after {
    content: "";
    position: absolute;
    width: 100%;
    background-color: rgb(234, 234, 245);
    height: 1px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 10px;
    z-index: -1;
}
.divide-heading {
    position: relative;
    z-index: 1;
    margin-bottom: 30px;
}
.outer-header {
    flex: 1;
    border-radius: 20px;
    width: 100%;
    border: 3px solid #D20021;
    padding: 100px 90px 60px 90px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.steps-header::before {
    content: "";
    background: #D20021;
    width: 80px;
    height: 80px;
    border-radius: 0;
    position: absolute;
    left: 0;
    bottom: 0;
    clip-path: polygon(0 0, 50% 50%, 100% 100%, 0% 100%);
}
.steps-header::after {
    content: "";
    background: #D20021;
    width: 150px;
    height: 100%;
    border-radius: 0;
    position: absolute;
    right: 0;
    bottom: 0;
    clip-path: polygon(50% 50%, 100% 0, 100% 100%, 0% 100%);
}
.steps-header {
    background-color: rgb(50 51 52);
    border-radius: 4px;
    height: 80px;
    width: 80%;
    margin: 0 auto;
    font-size: 40px;
    color: rgb(255,255,255);
    font-weight: 600;
    text-align: center;
    display: grid;
    align-content: center;
    padding: 20px 160px;
    position: relative;
    top: 46px;
    overflow: hidden;
}
.steps-header h3 {
    color: #fff;
    font-size: 33px;
    text-transform: capitalize;
}

section.myhome.bg-overlay {
    background-image: url(../images//insurance/happy.webp);
    padding: 220px 0px 120px 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    position: relative;
}
.exploring {
    margin-top: 47px;
}
button.modalbtn {
    margin-top: 36px;
    margin-right: 0;
    bottom: -12px;
    position: relative;
    text-transform: uppercase;
    background-color: #D20021 !important;
    color: var(--white);
    border-radius: 0px !important;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    font-family: var(--ltn__heading-font);
    padding: 10px 15px;
}
img.formpick {
    margin-top: 40px;
    width: 80%;
}
.newfeatuer h3 {
    color: #D20021;
    font-weight: 700;
    margin-bottom: 10px;
}
.cts-a-box p {
    margin-bottom: 0px;
}
.cts-a-box {
    padding: 22px 30px;
    border-style: solid;
    border-width: 0;
    border-radius: 0;
    position: relative;
    display: flex;
}
img.customersupport {
    max-width: 46px;
    padding-top: 12px;
}
.custsupport {
    margin: 0 15px 0 0;
    transform: rotate(0deg);
    text-align: center;
    background-color: #dc0e0e;
    border-radius: 50%;
    height: 70px;
    width: 70px;
}
section.last-cta {
    background: #fbd95e;
}
div#insur-form-sec {
    padding-top: 70px;
    padding-bottom: 70px;
}
.signle-insurance .btn {
    border-radius: 5px;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    font-family: var(--ltn__heading-font);
    padding: 10px 15px;}
.signle-insurance {
    transition: .1s;
    text-align: center;
    border: 1px solid #ddd;
    box-shadow: 0 0 0 0.3px #ddd;
    background: #fff;
}

.signle-insurance:hover{
    border-color: #D20021;
    box-shadow: 0 0 0 2px #D20021;
}

img.singleman {
    margin-top: -40px;
    margin-bottom: 15px;
}
.signle-insurance .btn:last-child {
    margin-right: 0;
    margin-bottom: -27px;
}
.newfeatuer {
    padding: 30px;
    background: #fff;
    height: 230px;
}
section.business {
    background-image: url(../images/insurance/insurancebg.png);
    padding-top: 80px;
    padding-bottom: 80px;
    background-size: cover;
    background-repeat: no-repeat;
}
img.xg {
    max-width: 75%;
    margin-top: 30px;
}
.mypolicyfeat {
    background-color: #eee;
    margin-left: 70px;
    padding: 30px 10px 0px;
}
h4.Simplified {
    color: #dc0e0e;
    font-size: 20px;
    font-weight: 400;
    text-transform: capitalize;
    line-height: 28px;
    margin-bottom: 10px;
}
.main-incididunt {
    margin-top: 50px;
    position: relative;
    margin-right: 100px;
}


.main-incididunt p{
    margin-bottom: 0px;
}
.main-incididunt h3 {
    color: #000000;
    font-weight: 800;
    margin-bottom: 15px;
    font-size: 25px;
}
img.sitelogo {
    width: 28%;
}
.incididunt::before{
content: '1';
    position: absolute;
    left: 0;
    font-size: 73px;
    color: rgba(78,86,97,.0588235294);
    font-family: Poppins,sans-serif;
    font-weight: 600;
    display: block;
    line-height: 1;
    margin-bottom: 0.9375rem;
    -webkit-transition: opacity .2s ease;
    -o-transition: opacity .2s ease;
    transition: opacity .2s ease;}

    .incididunt1::before{
        content: '2';
            position: absolute;
            left: 0;
            font-size: 73px;
            color: rgba(78,86,97,.0588235294);
            font-family: Poppins,sans-serif;
            font-weight: 600;
            display: block;
            line-height: 1;
            margin-bottom: 0.9375rem;
            -webkit-transition: opacity .2s ease;
            -o-transition: opacity .2s ease;
            transition: opacity .2s ease;}

 .incididunt2::before{
    content: '3';
     position: absolute;
     left: 0;
    font-size: 73px;
    color: rgba(78,86,97,.0588235294);
    font-family: Poppins,sans-serif;
    font-weight: 600;
    display: block;
    line-height: 1;
    margin-bottom: 0.9375rem;
    -webkit-transition: opacity .2s ease;
    -o-transition: opacity .2s ease;
    transition: opacity .2s ease;}

.incididunt {
    padding: 0 20px 25px 65px;
    border-style: solid;
    border-width: 0 0 1px;
    border-color: #e6ecf2;
    border-radius: 0;
  }
.incididunt1 {
    padding: 0 20px 25px 65px;
    border-style: solid;
    border-width: 0 0 1px;
    border-color: #e6ecf2;
    border-radius: 0;
    padding-top: 25px;
}
.incididunt2 {
    padding: 0 20px 25px 65px;
    border-style: solid;
    border-width: 0 0 1px;
    border-color: #e6ecf2;
    border-radius: 0;
    padding-top: 25px;
}
.Ourclient {
    border: 1px solid #edecec;
    padding: 30px;
    background: #f2f1f1;
    border-radius: 10px;
}
h2.consetur {
    margin-bottom: 20px;
}
section.InsuranceSimplified {
    padding-top: 80px;
   
}
.today {
    background-color: #fffefb;
    padding: 18px 30px;
    border-style: solid;
    border-radius: 10px;
    border-width: 0;
    border-color: #02010100;
    position: relative;
    display: flex;
}
img.agent {
    width: 40%;
}
section.Financialplanning h2 {
    margin-bottom: 22px;
}
.signle-insurance h3 {
    margin-bottom: 40px;
    margin-top: 40px;
    color: #000;
}
.imge-box {
    background-color: #d6d6d6;
    background-image: url(../images/insurance/yellowbg.svg);
    background-position: 0 100%;
    background-repeat: no-repeat;
    background-size: auto 738px;
    margin-bottom: 40px;
    border-radius: 10px;
    padding: 50px 40px 0px 40px;
    display: block;
    position: relative;
    margin-left: 180px;
}
.comparing-box p {
    margin-top: 26px;
}
.imge-box h3 {
    font-size: 29px;
    text-align: left;
    line-height: 30px;
    font-weight: 700;
    margin-bottom: 17px;
}
img.myinsure {
    margin: 0 auto;
    text-align: center;
    display: block;
  }
h2.Choose {
    font-size: 27px;
    text-align: center;
}
.affordable h2 {
    margin-bottom: 20px;
}
.theme-btn-1 {
    background-color: #D20021  !important;
    color: var(--white);
    border-radius: 0px !important;
}
section.myhome.bg-overlay::before {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
     content: "";
    background-color: transparent;
    background-image: linear-gradient(90deg,#f7f8fb 50%,#FFFFFF00 90%);
    opacity: .9;
    transition: background .3s,border-radius .3s,opacity .3s;

}
section.myhome h1{
    color: #201d1d;
    font-weight: 900;
    font-size: 50px;
    line-height: 63px;
    margin-bottom: 38px;
    text-transform: capitalize;
    position: relative;
}
.bnrwhitebox h3 {
    font-size: 33px;
    line-height: 40px;
    font-weight: 900;
    position: relative;
}
.bnrbox {
    transition: background .3s,border .3s,border-radius .3s,box-shadow .3s;
    background: #fff;
    padding: 20px;
    text-align: center;
    border-radius: 6px;
    margin: 10px;
    display: inline-block;
    width: 110px;
    background: #fff;
    border: 1px solid #d2d2d2;
}
section.pningabt {
    padding-top: 70px;
    padding-bottom: 100px;
}

.swiper-Ourclient {
    margin: 0 auto;
    display: block;
    text-align: center;
    width: 100%;
}
.bnrbox p {
    font-size: 16px;
    line-height: 23px;
    color: #000;
    margin-bottom: 0px;
    margin-top: 10px;
}
.bnrwhitebox {
    padding: 40px 15px 30px;
    background: #fffc;
    text-align: center;
    margin-left: 100px;
    border-radius: 8px 8px 0 0;
    position: relative;
}
img.Whizametlogo {
    width: 73% !important;
}
.Partner .swiper-button-prev {
    display: none;
}
.whychosebox {
    background: #fff;
    margin-top: 35px;
    padding: 30px 20px;
    box-shadow: 8px 9px 35px 0px rgba(145, 145, 145, 0.24);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

img.paying {
    margin-bottom: 20px;
}
.Journey h3 {
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    margin-bottom: 20px;
    font-weight: 700;
    line-height: 34px;
    padding-top: 30px;
    position: relative;
}
div#their {
    padding-top: 20px;
}
.Journey {
    margin-top: 20px;
}
.Journey h3::after {
    border-radius: 0;
    content: "";
    display: inline-block;
    height: 3px;
    left: 0;
    top: -2px;
    margin: 0;
    position: absolute;
    vertical-align: bottom;
    width: 50px;
    background-color: #D20021;
}
section#abtus .Journey h3 {
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    margin-bottom: 20px;
    font-weight: 700;
    line-height: 30px;
    padding-top: 30px;
    position: relative;
    font-size: 20px;
}
.Journey p{
    margin-bottom: 0px;
}
section#abtus .Journey {
    background: #fff;
    margin-top: 35px;
    padding: 30px 20px;
    box-shadow: 8px 9px 35px 0px rgba(145, 145, 145, 0.24);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
section.Securing {
    padding-top: 80px;
}
.whychosebox p {
    margin-bottom: 0px;
}
.Partner .swiper-button-next{
    display: none;
}
h3.Trusted.animated.fadeIn {
    color: #000000;
    font-weight: 800;
    margin-bottom: 15px;
    font-size: 22px;
}
p.enim {
    border-bottom: 1px solid;
    margin-bottom: 20px;
    padding-bottom: 20px;
}
.ctabnr {
    background: #201d1d;
    text-align: left;
    padding: 30px 40px;
}

span.ctabnrtwo a{
    color: #ffda47;
}

img.support1 {
    margin-right: 15px;
    float: left;
    margin-top: -3px;
}
span.ctabnrone {
    color: #fff;
    font-size: 20px;
    margin-right: 3px;
}
span.ctabnrtwo a {
    color: #ffda47;
    font-size: 19px;
}
span.ctabnrtwo a:hover {
    color: #D20021;
    font-size: 19px;
}
p.Compare {
    color: #000;
 margin-top: 20px;
 margin-bottom: 20px;
}
p.own {
    color: #000;
    margin-bottom: 0px;
    margin-top: 10px;
}
section.business .item {
    background: #ffffff none repeat scroll 0 0;
    overflow: hidden;
    padding: 30px;
    height: 300px;
    position: relative;
    z-index: 1;
    border: 1px solid #E5E5E5;
    border-top: 5px solid #D20021;
}
section.business .item:hover {
    background: #D20021 none repeat scroll 0 0;
    overflow: hidden;
    padding: 30px;
    position: relative;
    z-index: 1;
    border: 1px solid #E5E5E5;
    border-top: 5px solid #E5E5E5;
}
section.business  .item:hover i,
section.business  .item:hover h4,
section.business  .item:hover h5,
section.business  .item:hover a,
section.business  .item:hover p {
color: #ffffff !important;
}
div#botmservce {
    margin-top: 40px;
}
div#botmservce p {
    margin-bottom: 0px;
}
section.business h4 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 14px;
}
section.business .item:hover{
       border-top: 5px solid #ffda47;}

    section.policy-faqs .accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 5px 20px;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    background-color: #fff;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
}
ul.rolelist {
    list-style: none;
    padding-left: 0px;
}
.rolbox-01 p {
    margin-bottom: 14px;
    border-bottom: 1px solid;
    padding-bottom: 15px;
}
ul.rolelist li {
    color: #000;
    font-size: 16px;
    margin-top: 0px;
    line-height: 34px;
}
ul.rolelist i {
    margin-right: 14px;
}
img.agentteam {
    position: absolute;
    right: 0;
    bottom: 0px;
    max-width: 32%;
}
.rolbox-01{
      background-color: #cecece;
    background-image: url(../images/insurance/yellowbg.svg);
      background-position: 0 100%;
    background-repeat: no-repeat;
    background-size: auto 738px;
    border-radius: 10px;
    padding: 38px 40px 38px 40px;
    display: block;
    position: relative;
}
div#videosec {
    margin-top: 30px;
}
section.policy-faqs .accordion-button:not(.collapsed) {
    color: #fff;
    background-color: #c9002c;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .125);
    font-size: 18px;
}
section.policy-faqs .card-body {
    flex: 1 1 auto;
    padding: 2rem 16px;
    border: 1px solid #c00033;
    border-radius: 0px;
}
section.serivceform {
    padding-top: 100px;
        background-image: url(../images/insurance/greyb.JPG);
    background-size: cover;
    background-repeat: no-repeat;
}
.chatmsg {
    margin: 0 15px 0 0;
    transform: rotate(0deg);
    width: 56px;
    line-height: 54px;
    text-align: center;
    background-color: #dc0e0e;
    border-radius: 50%;
    height: 56px;
}
.react-responsive-modal-root .react-responsive-modal-modal {
    max-width: 100% !important;
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    background: #ffffff;
    box-shadow: unset;
    margin: 1.2rem;
    padding: 1.2rem;
    position: relative;
    overflow-y: auto;
}
.react-responsive-modal-root .react-responsive-modal-overlay {
    background: #ffffff !important;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1111;
}
.headercnt .box-body p a {
    color: #fff;
    font-size: 20px;
    font-weight: 800;
    line-height: 22px;
    letter-spacing: -.15px;
    margin-bottom: 0px;
    z-index: -111111 !important;
}
h3.nav-info-box-title.animated.fadeIn {
    margin: 0 0 5px;
    padding: 0;
    color: #fff;
    font-family: "Rubik",Sans-serif;
    font-size: 14px;
    font-weight: 400;
    text-transform: none;
    line-height: 22px;
    letter-spacing: -.15px;
}
.headercnt {
    background-color: #201d1d;
    padding: 18px 30px;
    border-style: solid;
    border-width: 0;
    border-color: #02010100;
    border-radius: 0;
    position: relative;
    display: flex;
}
section.policy-faqs {
    padding-top: 80px;
    padding-bottom: 80px;
}
div#forfaq {
    margin-top: 40px;
}
section.whyinsurance {
    padding-top: 80px;
 
}
.rolbox video {
    width: 100%;
    border-radius: 10px;
}
select.timerpicker {
    width: 70px;
}
select.timerpicker1 {
    width: 96px;
    margin-left: -13px;
}
@media (max-width: 374px) and (min-width: 320px){
    select.timerpicker {
        width: 63px;
    }
    select.timerpicker1 {
        width: 87px;
        margin-left: -13px;
    }
}
@media (max-width: 424px) and (min-width: 375px){
    select.timerpicker1 {
        width: 105px;
        margin-left: -13px;
    }
    select.timerpicker {
        width: 82px;
    }
}
@media (max-width: 424px) and (min-width: 375px){
    select.timerpicker1 {
    width: 105px;
    margin-left: -13px;
    }
    select.timerpicker {
    width: 82px;
    }
}
@media (max-width: 767px) and (min-width: 425px){
    select.timerpicker {
    width: 105px;
    }
    select.timerpicker1 {
    width: 108px;
    margin-left: 0px;
    }
}
@media (max-width: 423px) and (min-width: 413px){
    select.timerpicker1 {
    width: 105px;
    margin-left: 0px;
    }
    select.timerpicker {
    width: 100px;
    }
}
@media (max-width: 769px) and (min-width: 767px){
    label.cdtfc {
        background: #D20022;
        color: white;
        font-weight: bold;
        text-align: center;
        font-size: 17px;
        width: 100%;
        height: 62px;
        padding-top: 6px;
        /* border-radius: 21px; */
        margin: 0 auto;
        margin-bottom: 11px;
    }
}
@media (max-width: 823px) and (min-width: 819px){
    label.cdtfc {
        background: #D20022;
        color: white;
        font-weight: bold;
        text-align: center;
        font-size: 18px;
        width: 100%;
        height: 71px;
        padding-top: 6px;
        /* border-radius: 21px; */
        margin: 0 auto;
        margin-bottom: 11px;
    }
}
@media (max-width: 913px) and (min-width: 910px){
    label.cdtfc {
        background: #D20022;
        color: white;
        font-weight: bold;
        text-align: center;
        font-size: 19px;
        width: 100%;
        height: 66px;
        padding-top: 6px;
        /* border-radius: 21px; */
        margin: 0 auto;
        margin-bottom: 11px;
    }
}
@media (max-width: 1025px) and (min-width: 1023px){
    label.cdtfc {
        background: #D20022;
        color: white;
        font-weight: bold;
        text-align: center;
        font-size: 19px;
        width: 100%;
        height: 63px;
        padding-top: 6px;
        /* border-radius: 21px; */
        margin: 0 auto;
        margin-bottom: 11px;
    }
    select.timerpicker {
        padding: 2px;
        width: 49px;
        height: 48px;
    }
    select.timerpicker1 {
        width: 81px;
        margin-left: -18px;
        padding: 2px;
        height: 48PX;
    }
}
@media (max-width: 780px) and (min-width: 767px){
    select.timerpicker {
        padding: 0px;
        width: 38px;
        height: 48px;
    }
    select.timerpicker1 {
        width: 79px;
        margin-left: -1px;
        height: 48px;
        padding: 0px;
    }
}
@media (max-width: 1441px) and (min-width: 1439px){
    select.timerpicker {
        width: 92px;
        height: 48px;
    }
    select.timerpicker1 {
        width: 96px;
        margin-left: 0px;
        height: 48px;
    }
}
label.cdtfc {
    background: #d20022;
    color: #fff;
    font-size: 19px;
    font-weight: 700;
    height: 41px;
    margin: 0 auto 11px;
    padding-top: 6px;
    text-align: center;
    width: 100%;
}
@media (max-width: 393px) and (min-width: 389px){
    select.timerpicker {
        width: 90px;
    }
    select.timerpicker1 {
        width: 105px;
        margin-left: -8px;
    }
}
@media (max-width: 363px) and (min-width: 359px){
    select.timerpicker1 {
        width: 95px;
        margin-left: -7px;
    }
    select.timerpicker {
        width: 77px;
    }
}
@media (max-width: 363px) and (min-width: 359px){
    select.timerpicker {
        width: 77px;
    }
    select.timerpicker1 {
        width: 117px;
        margin-left: -11px;
    }
}
@media (max-width: 542px) and (min-width: 539px){
    select.timerpicker {
        width: 142px;
    }
    select.timerpicker1 {
        width: 146px;
        margin-left: 0px;
    }
}
@media (max-width: 414px) and (min-width: 411px){
    select.timerpicker {
        width: 100px;
    }
    select.timerpicker1 {
        width: 105px;
        margin-left: 0px;
    }
}
@media (max-width: 542px) and (min-width: 539px){
    label.cdtfc {
        background: #D20022;
        color: white;
        font-weight: bold;
        text-align: center;
        font-size: 19px;
        width: 80%;
        height: 65px;
        padding-top: 6px;
        /* border-radius: 21px; */
        margin: 0 auto;
        margin-bottom: 11px;
    }
}
@media (max-width: 282px) and (min-width: 279px){ 
    div#formdiv {
        display: inline-table;
    }
    span {
        display: none;
    }
    select.timerpicker1 {
        width: 133px;
        margin-left: 0px;
    }
    label.cdtfc {
        background: #D20022;
        color: white;
        font-weight: bold;
        text-align: center;
        font-size: 12px;
        width: 100%;
        height: 65px;
        padding-top: 6px;
        /* border-radius: 21px; */
        margin: 0 auto;
        margin-bottom: 11px;
    }
}
@media (max-width: 480px) and (min-width: 320px){
    label.cdtfc {
        background: #d20022;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        height: 65px;
        margin: 0 auto 11px;
        padding-top: 6px;
        text-align: center;
        width: 96%;
    }
    
}
@media (max-width: 480px) and (min-width: 320px){
    div#talored {
        margin-bottom: 0px;
    }
    section.Newleadform iframe {
        height: 200px;
        margin: 0 auto;
        display: block;
        text-align: center;
        float: none;
        width: 97%;
    }
    section.myhome h1 {
        color: #201d1d;
        font-weight: 900;
        font-size: 32px;
        line-height: 42px;
        margin-bottom: 15px;
        position: relative;
    }
    .headercnt a {
        padding: 0px;
        color: #fff !important;
    }
    .headercnt {
        background-color: #201d1d;
        padding: 8px 15px;}
    .bnrwhitebox2.text-center #msform input, #msform textarea {
     margin-bottom: 5px;
        letter-spacing: 0px;
    }
    
    .bnrwhitebox2.text-center input#submit {
        width: 100%;}
    #msform .action-button {
        width: 100%;}
    .bnrwhitebox2.text-center #msform .action-button-previous {
        width: 100%;
      left: 0;
       }
    .bnrwhitebox2.text-center h3 {
        font-size: 25px;
        font-weight: 800;
        text-transform: capitalize;
    }
    div#Massive-lead {
             padding-top: 25px;
    }
    section.myhome.bg-overlay2 a.theme-btn-1.btn.btn-effect-1 {
            font-size: 14px;
              padding: 10px 16px;}
    section.myhome.bg-overlay2 {
          padding: 113px 0px 50px 0;}
    .footer-top.dark-view.padding-tb {
        padding-top: 29px;
        padding-bottom: 50px;
    }
    section.red {
        background: #7a7878;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-top: -10px;
    }
    .insuranceplan {
        padding-top: 20px;
        text-align: left !important;
    }
    div#Experienced {
        margin-top: 10px;
    }
    .Needs-box {
        margin-bottom: 20px;
    }
    section.newwhychoose h2 {
        text-align: left;
    }
    section.newwhychoose {
        padding-top: 0px;
    }
    section.Newleadform .mainform {
        padding: 10px 10px 30px 10px;
        overflow-x: hidden;
    }
    section.Newleadform .col-xxl-12.col-xl-12.col-lg-12.col-md-12.col-12 {
        padding: 0px;
    }
    img.cont {
        width: 100%;
        margin-right: 0px;
        padding-top: 40px;
    }
    .newbnr ul li {
        color: #000;
        font-size: 16px;
        margin-right: 0px;
        line-height: 28px;
    }
    .newbnr h2 {
        line-height: 41px;
        text-align: left;
        font-size: 26px !important;
    }
    section.newlife {
  
         padding: 50px 10px 0px 10px;
       }
    .newbnr ul {
        margin: unset;
        display: block;
       text-align: left;
        padding-left: 20px;
    }
    .exploring {
        margin-top: 0px;
    }
    span.Massive {
        font-weight: 300;
        font-size: 26px;
        line-height: 31px;
    }
    .ltn__header-options > div {
        margin-right: 10px;
        margin-top: -11px;
    }
    .site-logo.go-top img {
        width: 18%;
    }
    section.Newleadform #msform input {
    height: 42px;}
    section.Newleadform .Newleadform-outer-box .mainform .mynewform form#msform input#submit {
        width: 110px;
        margin: 0px 0px 0px 0px;
    }
    .Newleadform-outer-box #msform .action-button {
        width: 110px;}
    section.Newleadform .nice-select {
        border-radius: 2px;
        line-height: 50px;
        margin-top: 8px;
        height: 38px;
        line-height: 36px;
        padding-left: 10px;
    }
    section.Newleadform #msform .action-button-previous {
     width: 110px;
     left: 0%;
       }
    section.Newleadform {
        padding-top: 38px;
    }
    .mainform {
        padding: 10px 10px 30px 10px;
        margin: 0 auto;
        display: block;
        text-align: center;
    }
    .ltn__utilize-menu-head {
      margin-bottom: 5px;}

    .ltn__utilize-menu > ul > li > a {
        display: block;
        padding: 8px 24px 8px 0;
        text-transform: capitalize;
    }
    .ltn__utilize-menu > ul > li {
        margin-top: 5px;
        color: #000;
        font-size: 16px;
        border-bottom: 1px solid #ecececd6;
        text-transform: capitalize !important;
    }
    section.lifeinsurance-features h3.Management {
        font-size: 16px;
    }
    section.comparing ul li {
        font-size: 16px;
        color: #000;
    }
    p.trusted.text-center br {
        display: none;
    }
    .Planningfeatures .financial-box {
            height: unset;}
    img.finiclapln {

        width: 100%;}
    section.Financialallleadform .fromback {
        margin-left: 0px;
        background: #efefef;
        padding: 30px 30px;
        border: 1px solid #c7c4c4;
    }
    section.Financialallleadform {
        padding-top: 50px;
         background-size: auto;
          padding-bottom: 50px;
    }
    img.support1 {
        margin-right: 6px;
        width: 24px;
    }
    section.myhome.bg-overlay .ctabnr {
        margin-left: 0px;
        position: relative;
    }
    .ltn__breadcrumb-area {
        background-color: var(--section-bg-1);
        margin-bottom: 66px !important;
        padding-top: 40px !important;
        padding-bottom: 0px !important;
    }
    section.contactdetail .fromback {
        margin-left: 0px;
        background: #efefef;
        padding: 30px 20px;}
    section.contactdetail .widget.ltn__social-media-widget {
      height: unset;
    }
    .calling-dtail a {
        font-size: 16px;
    }
    .fromback form input.form-rol {
        width: 120px;
          margin-bottom: 0px;
              height: 49px !important;}
    .fromback form textarea.form-control {
      margin-bottom: 18px;
    }
    .fromback label {
        font-size: 14px;
    }
    section.contactdetail .fromback form input.form-control {
        background-color: #fff;
        border: 1px solid;
        border-radius: 5px;
        border-color: #9a9c9e;
        height: 37px;}
    section.contactdetail .fromback {
        margin-top: 30px;
        margin-left: 0px;
        padding: 30px 20px;
    }
    section.contactdetail .ltn__social-media-2 ul li {
        list-style: none;
        display: inline-block;
        margin-right: 10px;
    }
    div#PascalCase {
        margin-top: 0px;
    }
    .calling-dtail {
       height: unset;}

    section.contactdetail {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    section.contct-below span.ctabnrtwo a{
        color: #ffda47;
        font-size: 20px;
        padding-left: 0px;
    }    
    section.contct-below .ctabnr {
        text-align: left;
        display: grid;
    }
    section.contct-below span.ctabnrone {
        color: #fff;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-right: 0px;
        font-size: 16px;
    }
    img.newagent {
        display: none;}
        section.contactpge {
            padding-top: 50px;
            padding-bottom: 30px;
        }
    img.myinsure {
        padding-top: 20px;
    }
    .imge-box h3 {
        font-size: 25px;
          }
    .imge-box {
       padding: 50px 20px 0px 20px;
       margin-left: 0px;
        margin-top: 25px;
        margin-bottom: 0px;
    }
    section.DIfferent-types {
        padding-top: 0px;
        padding-bottom: 16px;
    }
  

    .left-side-tb {
        background: #fff;
        padding: 15px;}
    section.lifeinsurance-features .divide-heading::after{
        display: none;
    }
    section.lifeinsurance-features .divide-heading span {
       width: 100%;}

    section.lifeinsurance-features li {
      width: 100%;}

    section.lifeinsurance-features {
        padding-top: 50px;
        padding-bottom: 50px;}
    .insuranceplan {
        padding-top: 20px;
    }
    section.serivceform {
          background-size: auto;
        }
    .insuranceplan h2 br {
        display: none;
    }
    section#faqs p.ipsum22 {
        margin-bottom: 0px;
    }
    .Journey h3 {
       line-height: 28px;}

    section.Securing {
        padding-top: 50px;
    }
    section.centersec {
         padding-top: 60px;
        padding-bottom: 60px;
    }
    .Management{
        padding: 20px 10px;
    }
    .financial-box {
        height: 320px;}

    .Management h3{
        font-size: 22px;
        padding: 20px 10px;
    }
    .signle-insurance h3 {
        margin-bottom: 14px;
        margin-top: 16px;
        color: #000;
    }
    section.Financial {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    section.Financialplanning {
        padding-top: 45px;
        padding-bottom: 0px;}
    section.policy-faqs .accordion-button {
       padding: 10px 20px;}
    p.ipsum22 br {
        display: none;
    }
    .rolbox video {
        margin-top: 30px;}
    .affordable.text-center p br {
        display: none;
    }
    .bnrwhitebox h3 {
        font-size: 25px;
        line-height: 32px;
        font-weight: 900;
        position: relative;
    }
    .ltn__header-options {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        position: absolute;
        right: 0;
        top: -56px;
    }


    div#hdr-cta {
        display: none;
    }

    
    .main-incididunt {
        margin-top: 22px;
        margin-right: 0px;
    }
    .incididunt::before {
            left: 12px;
        font-size: 70px;
           }
           .incididunt1::before {
            left: 12px;
        font-size: 70px;
           }
           .incididunt {
            padding: 0 10px 25px 55px;}   
            .incididunt1 {
                padding: 30px 10px 25px 55px;
            }
            section.last-cta {
                background: #fbd95e;
                padding: 30px 0px;
            }
            section#contactus .btn-wrapper.animated {
                text-align: left;
            }
            .custsupport {
                display: none;}
            .cts-a-box {
                padding: 0px 0px;}
            section.policy-faqs .accordion-button {
                line-height: 25px;}
section.policy-faqs {
    padding-top: 50px;
    padding-bottom: 50px;
}
            img.xg {
                max-width: 100%;
                margin-top: 30px;
            }      
      .incididunt2 {
        padding: 30px 10px 25px 55px;} 
    .incididunt2::before {
       left: 12px;
       font-size: 70px;
           }       
    section.InsuranceSimplified {
        padding-top: 50px;
    }
    ul.rolelist li {
        color: #000;
        font-size: 15px;
        margin-top: 0px;
        line-height: 26px;
        margin-bottom: 10px;
    }
    .rolbox-01 .btn-wrapper.animated {
        text-align: left;
    }
    .rolbox-01 {
        padding: 50px 20px 50px 20px;
    }
    img.agentteam {
        display: none;}
    div#videosec {
        margin-top: 0px;
    }
    section.whyinsurance {
        padding-top: 50px;
    }
    .signle-insurance {
           padding-bottom: 30px;
        margin-bottom: 65px;
    }
    div#insur-form-sec {
         padding-bottom: 0px;
    }
    .signle-insurance .btn:last-child {
        margin-right: 0;
        margin-bottom: 0px;
    }
    section.serivceform {
        padding-top: 50px;
        padding-bottom: 0px;
    }
    section.business {
        padding-top: 50px;
        padding-bottom: 50px;}
    .Policyfeatures {
        margin-top: 30px;
    }
    section#abtus .btn-wrapper.animated {
        text-align: left;
    }
    .mypolicyfeat {
        background-color: #eee;
        margin-left: 0px;
        padding: 30px 10px 0px;
        margin-top: 25px;
    }
    section.pningabt {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    span.ctabnrtwo a {
        color: #ffda47;
        font-size: 17px;
        padding-left: 46px;
    }
    span.ctabnrone {
        color: #fff;
        font-size: 15px;
        margin-right: 0px;
    }
    section.myhome.bg-overlay {
        padding: 107px 0px 60px 0;
    }
    .ctabnr {
        background: #201d1d;
        text-align: left;
        margin-left: 0px;
        padding: 20px 15px;
    }
    .bnrwhitebox {
        padding: 40px 15px 30px;
        background: #fffc;
        text-align: center;
        margin-left: 0px;
        border-radius: 8px 8px 0 0;
        margin-top: 25px;
        position: relative;
    }
    section.myhome.bg-overlay .btn-wrapper.animated {
        text-align: left;
    }

}
@media (max-width: 580px) and (min-width: 481px){
    div#talored {
        margin-bottom: 0px;
    }
    section.Newleadform iframe {
        height: 200px;
        margin: 0 auto;
        display: block;
        text-align: center;
        float: none;
        width: 97%;
    }
    section.myhome h1 {
        color: #201d1d;
        font-weight: 900;
        font-size: 32px;
        line-height: 42px;
        position: relative;
        margin-bottom: 15px;
    }
    .headercnt a {
        padding: 0px;
        color: #fff !important;
    }
    .headercnt {
        background-color: #201d1d;
        padding: 8px 15px;}
    #msform .action-button {
        width: 100%;}
    .bnrwhitebox2.text-center #msform .action-button-previous {
        width: 100%;
      left: 0;
       }
       .bnrwhitebox2.text-center input#submit {
        width: 100%;}
    #msform .action-button {
        width: 100%;}
    .bnrwhitebox2.text-center h3 {
        font-size: 25px;
        font-weight: 800;
        text-transform: capitalize;
    }
    div#Massive-lead {
         padding-top: 25px;
    }
    section.myhome.bg-overlay2 a.theme-btn-1.btn.btn-effect-1 {
        font-size: 14px;
          padding: 10px 16px;}
    section.myhome.bg-overlay2 {
        padding: 113px 0px 50px 0;}
    .footer-top.dark-view.padding-tb {
        padding-top: 29px;
        padding-bottom: 50px;
    }
    section.red {
        background: #7a7878;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-top: -10px;
    }
    .insuranceplan {
        padding-top: 20px;
        text-align: left !important;
    }
    .Needs-box {
        margin-bottom: 20px;
    }
    div#Experienced {
        margin-top: 10px;
    }
    section.newwhychoose h2 {
        text-align: left;
    }
    section.newwhychoose {
        padding-top: 0px;
    }
    section.Newleadform .mainform {
        padding: 10px 10px 30px 10px;
        overflow-x: hidden;
    }
    section.Newleadform .col-xxl-12.col-xl-12.col-lg-12.col-md-12.col-12 {
        padding: 0px;
    }
    img.cont {
        width: 100%;
        margin-right: 0px;
        padding-top: 40px;
    }
    .newbnr ul li {
        color: #000;
        font-size: 16px;
        margin-right: 0px;
        line-height: 14px;
    }
    .newbnr h2 {
        line-height: 41px;
        text-align: left;
        font-size: 26px !important;
    }
    section.newlife {
  
         padding: 50px 10px 0px 10px;
       }
    .newbnr ul {
        margin: unset;
        display: block;
           text-align: left;
        padding-left: 20px;
    }
    .exploring {
        margin-top: 0px;
    }
    span.Massive {
        font-weight: 300;
        font-size: 26px;
        line-height: 31px;
    }
    .ltn__header-options > div {
        margin-right: 10px;
        margin-top: -11px;
    }
    .site-logo.go-top img {
        width: 18%;
    }
    section.Newleadform #msform input {
        height: 42px;}
    section.Newleadform .Newleadform-outer-box .mainform .mynewform form#msform input#submit {
        width: 110px;
        margin: 0px 0px 0px 0px;
    }
    section.Newleadform .nice-select {
        border-radius: 2px;
        line-height: 50px;
        margin-top: 8px;
        height: 38px;
        line-height: 36px;
        padding-left: 10px;
    }
    .Newleadform-outer-box #msform .action-button {
        width: 110px;}
    section.Newleadform #msform .action-button-previous {
        width: 110px;
        left: 0%;
          }
    section.Newleadform {
        padding-top: 38px;
    }
    .mainform {
        padding: 10px 10px 30px 10px;
        margin: 0 auto;
        display: block;
        text-align: center;
    }
    .ltn__utilize-menu-head {
        margin-bottom: 5px;}
    .ltn__utilize-menu > ul > li > a {
        display: block;
        padding: 8px 24px 8px 0;
        text-transform: capitalize;
    }
    .ltn__utilize-menu > ul > li {
        margin-top: 5px;
        color: #000;
        font-size: 16px;
        border-bottom: 1px solid #ecececd6;
        text-transform: capitalize !important;
    }
    section.lifeinsurance-features h3.Management {
        font-size: 16px;
    }
    section.comparing ul li {
        font-size: 16px;
        color: #000;
    }
    p.trusted.text-center br {
    display: none;
}
    .Planningfeatures .financial-box {
        height: 320px;}
    img.finiclapln {
      
        width: 100%;}
    section.Financialallleadform .fromback {
        margin-left: 0px;
        background: #efefef;
        padding: 30px 30px;
        border: 1px solid #c7c4c4;
    }
    section.Financialallleadform {
        padding-top: 50px;
         background-size: auto;
          padding-bottom: 50px;
    }
    section.myhome.bg-overlay .ctabnr {
        margin-left: 0px;
        position: relative;
    }
    .ltn__breadcrumb-area {
        background-color: var(--section-bg-1);
        margin-bottom: 66px !important;
        padding-top: 40px !important;
        padding-bottom: 0px !important;
    }
    section.contactdetail .widget.ltn__social-media-widget {
        height: unset;
      }
    .calling-dtail a {
        font-size: 16px;
    }
    .fromback form input.form-rol {
        width: 120px;
          margin-bottom: 0px;
              height: 49px !important;}
    .fromback form textarea.form-control {
        margin-bottom: 18px;
      }
    .fromback label {
        font-size: 14px;
    }
    section.contactdetail .fromback form input.form-control {
        background-color: #fff;
        border: 1px solid;
        border-radius: 5px;
        border-color: #9a9c9e;
        height: 37px;}
    section.contactdetail .fromback {
        margin-top: 30px;
        margin-left: 0px;
        padding: 30px 20px;
    }
    section.contactdetail .ltn__social-media-2 ul li {
        list-style: none;
        display: inline-block;
        margin-right: 10px;
    }
    div#PascalCase {
        margin-top: 0px;
    }
    .calling-dtail {
        height: unset;}
    section.contactdetail {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    section.contct-below span.ctabnrtwo a {
        color: #ffda47;
        font-size: 20px;
        padding-left: 0px;
    } 
    img.newagent {
        display: none;}
        section.contactpge {
            padding-top: 50px;
            padding-bottom: 30px;
        }
    img.myinsure {
        padding-top: 20px;
    }
    .imge-box h3 {
        font-size: 25px;
          }
    .imge-box {
        padding: 50px 20px 0px 20px;
        margin-left: 0px;
         margin-top: 25px;
         margin-bottom: 0px;
     }

    .left-side-tb {
        background: #fff;
        padding: 15px;}
    section.lifeinsurance-features .divide-heading::after{
        display: none;
    }
    section.lifeinsurance-features .divide-heading span {
        width: 100%;}
    section.lifeinsurance-features li {
        width: 100%;}
    section.lifeinsurance-features {
        padding-top: 50px;
        padding-bottom: 50px;}
    .insuranceplan {
        padding-top: 20px;
    }
    section.serivceform {
        background-size: auto;
      }
    .insuranceplan h2 br {
        display: none;
    }
    section#faqs p.ipsum22 {
        margin-bottom: 0px;
    }
    .Journey h3 {
        line-height: 28px;}

    section.Securing {
        padding-top: 50px;
    }
    section.centersec {
        padding-top: 60px;
       padding-bottom: 60px;
   }
    .financial-box {
        height: 320px;}
    .Management h3{
        font-size: 22px;
        padding: 20px 10px;
    }
    .signle-insurance h3 {
        margin-bottom: 14px;
        margin-top: 16px;
        color: #000;
    }
    section.Financial {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    section.Financialplanning {
        padding-top: 45px;
        padding-bottom: 0px;}
    section.policy-faqs .accordion-button {
        padding: 10px 20px;}
    p.ipsum22 br {
        display: none;
    }
    .rolbox video {
        margin-top: 30px;}
    .affordable.text-center p br {
        display: none;
    }
    .bnrwhitebox h3 {
        font-size: 25px;
        line-height: 32px;
        font-weight: 900;
        position: relative;
    }
    .main-incididunt {
        margin-top: 22px;
        margin-right: 0px;
        position: relative;
    }
    .incididunt::before {
            left: 12px;
        font-size: 70px;
           }
           .incididunt1::before {
            left: 12px;
        font-size: 70px;
           }
           .incididunt {
            padding: 0 10px 25px 55px;}   
            .incididunt1 {
                padding: 30px 10px 25px 55px;
            }
            section.last-cta {
                background: #fbd95e;
                padding: 30px 0px;
            }
            section#contactus .btn-wrapper.animated {
                text-align: left;
            }
            .custsupport {
                display: none;}
            .cts-a-box {
                padding: 0px 0px;}
            section.policy-faqs .accordion-button {
                line-height: 25px;}
section.policy-faqs {
    padding-top: 50px;
    padding-bottom: 50px;
}
            img.xg {
                max-width: 100%;
                margin-top: 30px;
            }      
      .incididunt2 {
        padding: 30px 10px 25px 55px;} 
    .incididunt2::before {
       left: 12px;
       font-size: 70px;
           }       
    section.InsuranceSimplified {
        padding-top: 50px;
    }
    ul.rolelist li {
        color: #000;
        font-size: 15px;
        margin-top: 0px;
        line-height: 26px;
        margin-bottom: 10px;
    }
    .rolbox-01 .btn-wrapper.animated {
        text-align: left;
    }
    .rolbox-01 {
        padding: 50px 20px 50px 20px;
    }
    img.agentteam {
        display: none;}
    div#videosec {
        margin-top: 0px;
    }
    section.whyinsurance {
        padding-top: 50px;
    }
    .signle-insurance {
           padding-bottom: 30px;
        margin-bottom: 65px;
    }
    div#insur-form-sec {
         padding-bottom: 0px;
    }
    .signle-insurance .btn:last-child {
        margin-right: 0;
        margin-bottom: 0px;
    }
    section.serivceform {
        padding-top: 50px;
        padding-bottom: 0px;
    }
    section.business {
        padding-top: 50px;
        padding-bottom: 50px;}
    .Policyfeatures {
        margin-top: 30px;
    }
    section#abtus .btn-wrapper.animated {
        text-align: left;
    }
    .mypolicyfeat {
        background-color: #eee;
        margin-left: 0px;
        padding: 30px 10px 0px;
        margin-top: 25px;
    }

    section.pningabt {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    span.ctabnrtwo a{
        color: #ffda47;
        font-size: 17px;
        padding-left: 46px;
    }
    span.ctabnrone {
        color: #fff;
        font-size: 15px;
        margin-right: 0px;
    }
    section.myhome.bg-overlay {
        padding: 50px 0px 50px 0;}
    .ctabnr {
        background: #201d1d;
        text-align: left;
        margin-left: 0px;
        padding: 20px 15px;
    }
    .bnrwhitebox {
        padding: 40px 15px 30px;
        background: #fffc;
        text-align: center;
        margin-left: 0px;
        border-radius: 8px 8px 0 0;
        margin-top: 25px;
        position: relative;
    }
    section.DIfferent-types {
        padding-top: 0px;
        padding-bottom: 16px;
    }
    section.myhome.bg-overlay .btn-wrapper.animated {
        text-align: left;
    }
}
@media (max-width: 767px) and (min-width: 581px){
    div#talored {
        margin-bottom: 0px;
    }
    section.myhome h1 {
        color: #201d1d;
        font-weight: 900;
        font-size: 32px;
        line-height: 42px;
        margin-bottom: 15px;
        position: relative;
    }
    section.Newleadform iframe {
        height: 200px;
        margin: 0 auto;
        display: block;
        text-align: center;
        float: none;
        width: 97%;
    }
    .headercnt a {
        padding: 0px;
        color: #fff !important;
    }
    .headercnt {
        background-color: #201d1d;
        padding: 5px 15px;}
    .bnrwhitebox2.text-center #msform .action-button-previous {
        width: 100%;
      left: 0;
       }
       .bnrwhitebox2.text-center input#submit {
        width: 100%;}
    #msform .action-button {
        width: 100%;}
       #msform .action-button {
        width: 100%;}
    .bnrwhitebox2.text-center h3 {
        font-size: 25px;
        font-weight: 800;
        text-transform: capitalize;
    }
    div#Massive-lead {
        padding-top: 25px;
    }
    section.myhome.bg-overlay2 a.theme-btn-1.btn.btn-effect-1 {
        font-size: 14px;
          padding: 10px 16px;}
    section.myhome.bg-overlay2 {
        padding: 113px 0px 50px 0;}
    section.red {
        background: #7a7878;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-top: -10px;
    }
    .insuranceplan {
        padding-top: 20px;
        text-align: left !important;
    }
    .Needs-box {
        margin-bottom: 20px;
    }
    div#Experienced {
        margin-top: 10px;
    }
    section.newwhychoose h2 {
        text-align: left;
    }
    section.newwhychoose {
        padding-top: 0px;
    }
    section.Newleadform .mainform {
        padding: 10px 10px 30px 10px;
        overflow-x: hidden;
    }
    section.Newleadform .col-xxl-12.col-xl-12.col-lg-12.col-md-12.col-12 {
        padding: 0px;
    }
    .newbnr ul li {
        color: #000;
        font-size: 16px;
        margin-right: 0px;
        line-height: 14px;
    }
    img.cont {
        width: 100%;
        margin-right: 0px;
        padding-top: 40px;
    }
    .newbnr h2 {
        line-height: 41px;
        text-align: left;
        font-size: 26px !important;
    }
    section.newlife {
  
        padding: 50px 10px 0px 10px;
      }
    .newbnr ul {
        margin: unset;
        display: block;
        text-align: left;
        padding-left: 20px;
    }
    .exploring {
        margin-top: 0px;
    }
    span.Massive {
        font-weight: 300;
        font-size: 26px;
        line-height: 31px;
    }
    .ltn__header-options > div {
        margin-right: 10px;
        margin-top: -11px;
    }
    .site-logo.go-top img {
        width: 18%;
    }
    section.Newleadform #msform input {
        height: 42px;}
    section.Newleadform .Newleadform-outer-box .mainform .mynewform form#msform input#submit {
        width: 110px;
        margin: 0px 0px 0px 0px;
    }
    section.Newleadform .nice-select {
        border-radius: 2px;
        line-height: 50px;
        margin-top: 8px;
        height: 38px;
        line-height: 36px;
        padding-left: 10px;
    }
    .Newleadform-outer-box #msform .action-button {
        width: 110px;}
    section.Newleadform #msform .action-button-previous {
        width: 110px;
        left: 0%;
          }
    section.Newleadform {
        padding-top: 38px;
    }
    .mainform {
        padding: 10px 10px 30px 10px;
        margin: 0 auto;
        display: block;
        text-align: center;
    }
    .ltn__utilize-menu-head {
        margin-bottom: 5px;}

    .ltn__utilize-menu > ul > li > a {
        display: block;
        padding: 8px 24px 8px 0;
        text-transform: capitalize;
    }
    .ltn__utilize-menu > ul > li {
        margin-top: 5px;
        color: #000;
        font-size: 16px;
        border-bottom: 1px solid #ecececd6;
        text-transform: capitalize !important;
    }
    section.lifeinsurance-features h3.Management {
        font-size: 16px;
    }
    section.comparing ul li {
        font-size: 16px;
        color: #000;
    }
    .Planningfeatures .financial-box {
        height: 320px;}
    
    img.finiclapln {

        width: 100%;}
    section.Financialallleadform .fromback {
        margin-left: 0px;
        background: #efefef;
        padding: 30px 30px;
        border: 1px solid #c7c4c4;
    }
    section.Financialallleadform {
        padding-top: 50px;
         background-size: auto;
          padding-bottom: 50px;
    }
    section.myhome.bg-overlay .ctabnr {
        margin-left: 0px;
        position: relative;
    }
    .ltn__breadcrumb-area {
        background-color: var(--section-bg-1);
        margin-bottom: 66px !important;
        padding-top: 40px !important;
        padding-bottom: 0px !important;
    }
    section.contactdetail .widget.ltn__social-media-widget {
        height: unset;
      }
    .calling-dtail a {
        font-size: 16px;
    }
    .fromback form input.form-rol {
        width: 120px;
          margin-bottom: 0px;
              height: 49px !important;}
    .fromback form textarea.form-control {
        margin-bottom: 18px;
      }
    .fromback label {
        font-size: 14px;
    }
    section.contactdetail .fromback form input.form-control {
        background-color: #fff;
        border: 1px solid;
        border-radius: 5px;
        border-color: #9a9c9e;
        height: 37px;}
    section.contactdetail .fromback {
        margin-top: 30px;
        margin-left: 0px;
        padding: 30px 20px;
    }
    section.contactdetail .ltn__social-media-2 ul li {
        list-style: none;
        display: inline-block;
        margin-right: 10px;
    }
    div#PascalCase {
        margin-top: 0px;
    }
    .calling-dtail {
        height: unset;}
    section.contactdetail {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    section.contct-below span.ctabnrtwo {
        color: #ffda47;
        font-size: 20px;
        padding-left: 0px;
    } 
  
    img.newagent {
        display: none;}
        section.contactpge {
            padding-top: 50px;
            padding-bottom: 30px;
        }
    img.myinsure {
        padding-top: 20px;
    }
    .imge-box h3 {
        font-size: 25px;
          }
    .imge-box {
        padding: 50px 20px 0px 20px;
        margin-left: 0px;
         margin-top: 25px;
         margin-bottom: 0px;
     }
    section.DIfferent-types {
    padding-top: 0px;
    padding-bottom: 16px;
}
  
    .left-side-tb {
        background: #fff;
        padding: 15px;}
    section.lifeinsurance-features .divide-heading::after{
        display: none;
    }
    section.lifeinsurance-features .divide-heading span {
        width: 100%;}
    section.lifeinsurance-features li {
        width: 100%;}
    section.lifeinsurance-features {
        padding-top: 50px;
        padding-bottom: 50px;}
    .insuranceplan {
        padding-top: 20px;
    }
    section.serivceform {
        background-size: auto;
      }
    .insuranceplan h2 br {
        display: none;
    }
    section#faqs p.ipsum22 {
        margin-bottom: 0px;
    }
    .Journey h3 {
        line-height: 28px;}

    section.centersec {
        padding-top: 60px;
       padding-bottom: 60px;
   }
    .financial-box {
        height: 320px;}
    .Management h3{
        font-size: 22px;
        padding: 20px 10px;
    }
    .signle-insurance h3 {
        margin-bottom: 14px;
        margin-top: 16px;
        color: #000;
    }
    section.Financial {
    padding-top: 50px;
    padding-bottom: 50px;
}
    section.Financialplanning {
        padding-top: 45px;
        padding-bottom: 0px;}
    section.policy-faqs .accordion-button {
        padding: 10px 20px;}
    p.ipsum22 br {
        display: none;
    }
    .rolbox video {
        margin-top: 30px;}
    .affordable.text-center p br {
        display: none;
    }
    .bnrwhitebox h3 {
        font-size: 25px;
        line-height: 32px;
        font-weight: 900;
        position: relative;
    }
    .main-incididunt {
        margin-top: 22px;
        margin-right: 0px;
        position: relative;
    }

    .incididunt::before {
            left: 12px;
        font-size: 70px;
           }
           .incididunt1::before {
            left: 12px;
        font-size: 70px;
           }
           .incididunt {
            padding: 0 10px 25px 55px;}   
            .incididunt1 {
                padding: 30px 10px 25px 55px;
            }
            section.last-cta {
                background: #fbd95e;
                padding: 30px 0px;
            }
            section#contactus .btn-wrapper.animated {
                text-align: left;
            }
            .custsupport {
                display: none;}
            .cts-a-box {
                padding: 0px 0px;}
            section.policy-faqs .accordion-button {
                line-height: 25px;}
section.policy-faqs {
    padding-top: 50px;
    padding-bottom: 50px;
}
            img.xg {
                max-width: 100%;
                margin-top: 30px;
            }      
      .incididunt2 {
        padding: 30px 10px 25px 55px;} 
    .incididunt2::before {
       left: 12px;
       font-size: 70px;
           }       
    section.InsuranceSimplified {
        padding-top: 50px;
    }
    ul.rolelist li {
        color: #000;
        font-size: 15px;
        margin-top: 0px;
        line-height: 26px;
        margin-bottom: 10px;
    }
    .rolbox-01 .btn-wrapper.animated {
        text-align: left;
    }
    .rolbox-01 {
        padding: 50px 20px 50px 20px;
    }
    img.agentteam {
        display: none;}
    div#videosec {
        margin-top: 0px;
    }
    section.whyinsurance {
        padding-top: 50px;
    }
    .signle-insurance {
           padding-bottom: 30px;
        margin-bottom: 65px;
    }
    div#insur-form-sec {
         padding-bottom: 0px;
    }
    .signle-insurance .btn:last-child {
        margin-right: 0;
        margin-bottom: 0px;
    }
    section.serivceform {
        padding-top: 50px;
        padding-bottom: 0px;
    }
    section.business {
        padding-top: 50px;
        padding-bottom: 50px;}
    .Policyfeatures {
        margin-top: 30px;
    }
    section#abtus .btn-wrapper.animated {
        text-align: left;
    }
    .mypolicyfeat {
        background-color: #eee;
        margin-left: 0px;
        padding: 30px 10px 0px;
        margin-top: 25px;
    }
    section.pningabt {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    span.ctabnrtwo {
        color: #ffda47;
        font-size: 17px;
        padding-left: 46px;
    }
    span.ctabnrone {
        color: #fff;
        font-size: 15px;
        margin-right: 0px;
    }
    section.myhome.bg-overlay {
        padding: 50px 0px 50px 0;}
    .ctabnr {
        background: #201d1d;
        text-align: left;
        margin-left: 0px;
        padding: 20px 15px;
    }
    .bnrwhitebox {
        padding: 40px 15px 30px;
        background: #fffc;
        text-align: center;
        margin-left: 0px;
        border-radius: 8px 8px 0 0;
        margin-top: 25px;
        position: relative;
    }
    section.myhome.bg-overlay .btn-wrapper.animated {
        text-align: left;
    }
}
@media (max-width: 991px) and (min-width: 768px){
    section.Newleadform iframe {
      height: 400px;
        margin: 0 auto;
        display: block;
        text-align: center;
        float: none;
        width: 97%;
    }
    .ltn__header-options > div:last-child {
        right: 42%;
        position: absolute;
        top: 27px;
    }
    .headercnt {
        background-color: #201d1d;
        padding: 18px 13px;}
  
    .bnrwhitebox2.text-center #msform .action-button-previous {
       left: 0%;
        margin: auto;
        width: 130px;
    }
    span.Massive {
        font-weight: 300;
        font-size: 34px;
        line-height: 27px;
    }
    section.myhome.bg-overlay2 {
        padding: 120px 0px 60px 0;
    }
    section.myhome.bg-overlay2 h1 {
        font-size: 40px !important;
        line-height: 50px !important;
    }
    section.myhome.bg-overlay2 a.theme-btn-1.btn.btn-effect-1 {
        font-size: 14px;
          padding: 10px 16px;}
    ul.lab-ul.active {
        width: 300px;
        z-index: 11111;
    }
    .footer-top.dark-view.padding-tb {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    section.formcontact h3 {
        font-size: 18px;
        line-height: 10px;
    }
    img.cont {
        display: none;
    }
    section.newlife section.Newleadform {
        padding-top: 30px;
    }
    .newbnr ul li {
        color: #000;
        font-size: 18px;
        margin-right: 36px;
    }
    div#whyimg {
        display: none;
    }
    .site-logo.go-top img {
        width: 45%;
    }
    .mainform {
        padding: 30px;
        margin: 0 auto;
        display: block;
        text-align: center;
    }
    
    .ltn__utilize-menu-head {
        margin-bottom: 5px;}
  
      .ltn__utilize-menu > ul > li > a {
          display: block;
          padding: 8px 24px 8px 0;
          text-transform: capitalize;
      }
      .ltn__utilize-menu > ul > li {
          margin-top: 5px;
          color: #000;
          font-size: 16px;
          border-bottom: 1px solid #ecececd6;
          text-transform: capitalize !important;
      }
    section.comparing ul li {
        font-size: 16px;
        color: #000;
    }
    p {
        font-size: 16px;
        line-height: 26px;
    }
    .Planningfeatures .Management {
        font-size: 17px;
    }
    .Planningfeatures .financial-box {
        height: 390px;}
    .find-lead #msform .action-button-previous {
        width: 120px;
        left: 0%;
    }
    .steps-header h3 {
        color: #fff;
        margin-bottom: 0px;
        font-size: 23px;
        text-transform: capitalize;
    }
    section.Allleadform input.next.action-button {
      max-width: 120px;}
      .find-lead form#msform .nice-select {
        height: 38px;
        border-radius: 2px;
        margin-bottom: 10px;
        background-color: #f0f0f0;
        line-height: 38px;
    }
    .find-lead form#msform input#submit {
        max-width: 120px;
        width: 120px;
        font-size: 14px !important;
        height: 46px !important;
        font-weight: bold;
        background: #999896;
        color: white;
        border: 0 none;
        border-radius: 5px;
        border-radius: 0px;
        margin-top: 10px !important;
        cursor: pointer;
      margin-left: 0px !important;
      margin-right: 0px !important;
      margin-bottom: 0px !important;
        padding: 10px 5px;
        position: relative;
        position: relative;
        text-transform: uppercase;
        background-color: #D20021 !important;
        color: var(--white);
    }
    .outer-header {
        flex: 1;
        border-radius: 20px;
        width: 100%;
        border: 3px solid #D20021;
        padding: 100px 30px 60px 30px;}
    img.finiclapln {

        width: 100%;}
    section.Financialallleadform .fromback {
        margin-left: 0px;
        background: #efefef;
        padding: 30px 30px;
        border: 1px solid #c7c4c4;
    }
    section.myhome.bg-overlay .ctabnr {
        margin-left: 0px;
        position: relative;
    }
    .ltn__right-sidebar {
        margin-top: 40px !important;
    }
    
    .calling-dtail .Journey h3 {
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        margin-bottom: 10px;
        padding-top: 15px;
    }
    section.contactdetail .ltn__social-media-2 ul li {
        list-style: none;
        display: inline-block;
        margin-right: 7px;
    }
    section.contactdetail .widget.ltn__social-media-widget {
      height: unset;
    }
    div#PascalCase {
        margin-top: 0px;
    }
    .calling-dtail a {
        font-size: 18px;
    }
    .calling-dtail {
      height: unset;}
    section.contactdetail {
        padding-top: 80px;
        padding-bottom: 50px;
    }
    section.contactdetail .fromback {
        margin-left: 0px;
        padding: 30px 20px;}

    section#faqs p.ipsum22 {
        margin-bottom: 0px;
    }
    img.newagent {
        width: 20%;
        margin-top: -97px;
    }  
    section.contct-below span.ctabnrtwo {
        color: #ffda47;
        font-size: 20px;
        padding-left: 0px;
    }
    section.contct-below span.ctabnrone {
        color: #fff;
        font-size: 20px;
        margin-right: 8px;
    }
    section.contactpge p br {
        display: none;
    }
    section.contactpge {
      padding-top: 80px;
        padding-bottom: 80px;}
    section.policy-faqs .accordion-button {
       padding: 15px 20px;}

    .imge-box {
      margin-left: 0px;
    }
    section.DIfferent-types {
        padding-top: 30px;
        padding-bottom: 50px;
    }
    div#lowest {
        padding-right: 140px;
    }
    section.centersec {
    background-position: bottom;
    }
    section.Financialplanning {
        padding-top: 80px;
        padding-bottom: 10px;}
    .insuranceplan h2 br {
        display: none;
    }
    .bnrwhitebox h3 {
        font-size: 28px;
        position: relative;
        line-height: 34px;}
    section.pningabt {
        padding-top: 70px;
        padding-bottom: 70px;
    }
    section#abtus .Journey {
        background: #fff;
        margin-top: 35px;
        height: 320px;}
    .affordable.text-center p br {
        display: none;
    }
    .ltn__header-options {
        display: -webkit-box;
        position: absolute;
        right: 0;
        top: -50px;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
    }
    p.ipsum02 br {
        display: none;
    }
    .incididunt::before {
        font-size: 75px;}
    .incididunt1::before {
             font-size: 75px;}
             .incididunt2::before {
                font-size: 75px;}
    .incididunt {
        padding: 0 0px 25px 50px;}
        
        img.xg {
            max-width: 100%;}
        .incididunt1 {
            padding: 30px 0px 25px 50px;}

            .incididunt2 {
                padding: 30px 0px 25px 50px;}
    .main-incididunt {
        margin-top: 50px;
        margin-right: 0px;
        position: relative;
    }
    div#videosec {
        margin-top: 5px;
    }
    section#contactus .btn {
        padding: 12px 12px;
    }
    section.InsuranceSimplified {
        padding-top: 40px;
    }
    .custsupport {
        display: none;
    }
    section.policy-faqs .accordion-button {
        line-height: 30px;}
    section.business {
     background-position: top;
        padding-top: 23px;
        padding-bottom: 40px;
          }
          .rolbox {
            display: none;
        }      
    div#insur-form-sec {
        padding-top: 70px;
        padding-bottom: 0px;
    }
    p.ipsum22 br {
        display: none;
    }
    section.serivceform {
        padding-top: 80px;
        padding-bottom: 0px;
    }
    section#abtus h2.Choose {
        font-size: 25px !important;
        line-height: 35px;
        margin-bottom: 24px;
    }
    .signle-insurance {
        margin-bottom: 95px;
    }
    .newfeatuer {
        padding: 20px;
        background: #fff;
        height: unset;
    }
    .mypolicyfeat {
        background-color: #eee;
        margin-left: 0px;
        padding: 30px 10px 0px;
    }
    span.ctabnrtwo {
        color: #ffda47;
        font-size: 18px;
        padding-left: 47px;
    }
    span.ctabnrone {
        color: #fff;
        font-size: 17px;
        margin-right: 0px;
    }
    .ctabnr {
        background: #201d1d;
        text-align: left;
        margin-left: 0px;
        padding: 20px 20px;
    }
    .bnrwhitebox {
     margin-left: 0px;
     position: relative;}

    section.myhome.bg-overlay {
        padding: 120px 0px 60px 0;
         
}

    section.myhome.bg-overlay h1 {
        font-size: 40px !important;
        line-height: 50px !important;
    }
    .headercnt .box-body p a {
        color: #fff;
        font-size: 22px;}
    .chatmsg {
     width: 56px;
    line-height: 53px;
    height: 54px;
    }
    .ltn__header-5 .ltn__header-options {
        margin-left: 213px;
    }
}
@media (max-width: 1023px) and (min-width: 992px){
    section.Newleadform iframe {
        width: 365px;
        height: 365px;
    }
    .ltn__header-options > div:last-child {
        right: 42%;
        position: absolute;
        top: 27px;
    }
    .bnrwhitebox2.text-center #msform .action-button-previous {
            left: 38%;
      }
    .footer-top.dark-view.padding-tb {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .menu>ul>li>a {
        color: #101115;
        font-size: 15px  !important;
        font-weight: 700;
        padding: 15px 15px  !important;
        text-transform: capitalize;
    }
    .headercnt {
        background-color: #201d1d;
        padding: 9px 9px;
        margin-top: -10px;
    }
    section.formcontact h3 {
        font-size: 18px;
        line-height: 10px;
    }
    .exploring {
        margin-top: 0px;
    }
    .site-logo.go-top img {
        width: 37%;
    }
    section.Newleadform .Newleadform-outer-box .mainform .mynewform form#msform input#submit {
      margin: 0px 0px 0px 120px;
    }
    section.Newleadform #msform .action-button-previous {
     left: 29%;
       }
    .mainform {
        padding: 30px 30px 50px 30px;}
    .ltn__utilize-menu-head {
        margin-bottom: 5px;}
  
      .ltn__utilize-menu > ul > li > a {
          display: block;
          padding: 8px 24px 8px 0;
          text-transform: capitalize;
      }
      .ltn__utilize-menu > ul > li {
          margin-top: 5px;
          color: #000;
          font-size: 16px;
          border-bottom: 1px solid #ecececd6;
          text-transform: capitalize !important;
      }
    section.comparing ul li {
        font-size: 16px;
        color: #000;
    }
    .Planningfeatures .financial-box {
        height: 310px;}
    img.finiclapln {

        width: 100%;}
    section.Financialallleadform .fromback {
        margin-left: 0px;
        background: #efefef;
        padding: 30px 30px;
        border: 1px solid #c7c4c4;
    }
    section.myhome.bg-overlay .ctabnr {
        margin-left: 0px;
        position: relative;
    }
    .calling-dtail {
       height: unset;}

  section.contactdetail .widget.ltn__social-media-widget {
        height: unset;
    }
.calling-dtail a {
        font-size: 18px;
    }
    section.contactdetail {
        padding-top: 80px;
        padding-bottom: 60px;
    }
    section.contactdetail .fromback {
        margin-left: 0px;
        padding: 30px 20px;
    }
    div#PascalCase {
        margin-top: 0px;
    }
    img.newagent {
        width: 16%;
        margin-top: -107px;}

    .imge-box {
     margin-left: 0px;
    }
    section.centersec {
      background-position: bottom;}

    .financial-box {
       height: 200px;}

    section.Financialplanning {
       padding-bottom: 20px;}

    img.singleman {
        margin-top: -40px;
        margin-bottom: 0px;
        width: 50%;
    }

    .insuranceplan h2 br {
        display: none;
    }
    section.policy-faqs .accordion-button {
        padding: 10px 20px;}

    section.business .item {
        height: 225px;}
    .ltn__header-options {
        position: absolute;
        right: 0;
        top: -52px;
        display: flex;}
    .signle-insurance h3 {
        font-size: 17px;
    }
    p.ipsum02 br {
        display: none;
    }
    .incididunt::before {
        font-size: 75px;}
    .incididunt1::before {
             font-size: 75px;}
             .incididunt2::before {
                font-size: 75px;}
    .incididunt {
        padding: 0 0px 25px 50px;}
        
        img.xg {
            max-width: 100%;}
        .incididunt1 {
            padding: 30px 0px 25px 50px;}

            .incididunt2 {
                padding: 30px 0px 25px 50px;}
    .main-incididunt {
        margin-top: 50px;
        margin-right: 0px;
        position: relative;
    }
    div#videosec {
        margin-top: 5px;
    }
    section#contactus .btn {
        padding: 12px 12px;
    }
    section.InsuranceSimplified {
        padding-top: 40px;
    }
    .custsupport {
        display: none;
    }
    section.policy-faqs .accordion-button {
        line-height: 30px;}
    section.business {
     background-position: top;
        padding-top: 23px;
        padding-bottom: 40px;
          }
          ul.rolelist li {
            color: #000;
            font-size: 16px;
            margin-bottom: 10px;
            margin-top: 0px;
            line-height: 26px;
        }
    div#insur-form-sec {
        padding-top: 70px;
        padding-bottom: 0px;
    }
    p.ipsum22 br {
        display: none;
    }
    section.serivceform {
        padding-top: 80px;
        padding-bottom: 0px;
    }
    section#abtus h2.Choose {
        font-size: 25px !important;
        line-height: 35px;
        margin-bottom: 24px;
    }
    .signle-insurance {
        margin-bottom: 95px;
    }
    .newfeatuer {
        padding: 20px;
        background: #fff;
        height: unset;
    }
    .mypolicyfeat {
        background-color: #eee;
        margin-left: 0px;
        padding: 30px 10px 0px;
    }
    span.ctabnrtwo {
     font-size: 16px;
    padding-left: 0px;
    }
    span.ctabnrone {
        color: #fff;
        font-size: 17px;
        margin-right: 0px;
    }
    .ctabnr {
        background: #201d1d;
        text-align: left;
        margin-left: 0px;
        padding: 20px 20px;
    }
    .bnrwhitebox {
     margin-left: 0px;
     position: relative;}

    section.myhome.bg-overlay {
    padding: 80px 0px 60px 0;}

    section.myhome.bg-overlay h1 {
        font-size: 45px;
        line-height: 55px;
    }
    .headercnt .box-body p a {
        color: #fff;
        font-size: 15px;
    }
    .chatmsg {
     width: 56px;
    line-height: 53px;
    height: 54px;
    }
    .ltn__header-5 .ltn__header-options {
        margin-left: 213px;
    }
}
@media (max-width: 1199px) and (min-width: 1024px){
    section.Newleadform iframe {
        width: 365px;
        height: 365px;
    }
    .ltn__header-options > div:last-child {
        right: 0;
        position: absolute;
        top: -25px;
    }
    .bnrwhitebox2.text-center #msform .action-button-previous {
        left: 38%;
  }
    section.myhome.bg-overlay2 h1 {
        font-size: 45px;
        line-height: 55px;
    }
    .header-bottom .menu>ul {
        margin-right: 0px !important;
        padding-left: 30px;
    }
  
    .header-bottom .menu>ul>li>a {
        color: #101115;
        font-size: 15px;
        font-weight: 700;
        padding: 15px 15px;
   
    }
    .headercnt {
        background-color: #201d1d;
        padding: 10px 9px;
        margin-top: -10px;
    }
    section.formcontact h3 {
        font-size: 18px;
        line-height: 10px;
    }
    .exploring {
        margin-top: 0px;
    }
    .site-logo.go-top img {
        width: 37%;
    }
    section.Newleadform #msform .action-button-previous {
      left: 19%;
     }
    section.Newleadform .Newleadform-outer-box .mainform .mynewform form#msform input#submit {
      margin: 0px 0px 0px 71px;
    }
    .ltn__utilize-menu-head {
        margin-bottom: 5px;}
  
      .ltn__utilize-menu > ul > li > a {
          display: block;
          padding: 8px 24px 8px 0;
          text-transform: capitalize;
      }
      .ltn__utilize-menu > ul > li {
          margin-top: 5px;
          color: #000;
          font-size: 16px;
          border-bottom: 1px solid #ecececd6;
          text-transform: capitalize !important;
      }
    .Planningfeatures .financial-box {
        height: 330px;}

    section.comparing ul li {
        font-size: 16px;
        color: #000;
    }
    img.finiclapln {

        width: 100%;}
    section.Financialallleadform .fromback {
        margin-left: 0px;
        background: #efefef;
        padding: 30px 30px;
        border: 1px solid #c7c4c4;
    }
    section.myhome.bg-overlay .ctabnr {
        margin-left: 0px;
        position: relative;
    }
    section.contactdetail .widget.ltn__social-media-widget {
       height: unset;
    }
    section.contactdetail .fromback {
        margin-left: 0px;}
    .calling-dtail {
       height: unset;
         }
    div#PascalCase {
        margin-top: 0px;
    }
    img.newagent {
        width: 16%;
        margin-top: -109px;}
    .imge-box {
      margin-left: 0px;
    }
   
    .financial-box {
        height: 200px;}
    section.Financialplanning {
        padding-top: 80px;
        padding-bottom: 10px;}
    img.singleman {
        margin-top: -40px;
        margin-bottom: 0px;
        width: 55%;
    }
    .insuranceplan h2 br {
        display: none;
    }
    section.centersec {
        background-position: bottom;}
    section.policy-faqs .accordion-button {
        padding: 10px 20px;}
    section.business .item {
        height: 225px;}
    .signle-insurance h3 {
        font-size: 17px;
    }
    p.ipsum02 br {
        display: none;
    }
    .incididunt::before {
        font-size: 75px;}
    .incididunt1::before {
             font-size: 75px;}
             .incididunt2::before {
                font-size: 75px;}
    .incididunt {
        padding: 0 0px 25px 50px;}
        
        img.xg {
            max-width: 100%;}
        .incididunt1 {
            padding: 30px 0px 25px 50px;}

            .incididunt2 {
                padding: 30px 0px 25px 50px;}
    .main-incididunt {
        margin-top: 50px;
        margin-right: 0px;
        position: relative;
    }
    div#videosec {
        margin-top: 5px;
    }
    section#contactus .btn {
        padding: 12px 12px;
    }
    section.InsuranceSimplified {
        padding-top: 40px;
    }
    .custsupport {
        display: none;
    }
    section.policy-faqs .accordion-button {
        line-height: 40px;
    }
    section.business {
     background-position: top;
        padding-top: 23px;
        padding-bottom: 40px;
          }
          ul.rolelist li {
            color: #000;
            font-size: 16px;
            margin-bottom: 10px;
            margin-top: 0px;
            line-height: 26px;
        }
        .cts-a-box p {
            margin-bottom: 0px;
            font-size: 16px;
        }
    div#insur-form-sec {
        padding-top: 70px;
        padding-bottom: 0px;
    }
    p.ipsum22 br {
        display: none;
    }
    section.serivceform {
        padding-top: 80px;
        padding-bottom: 0px;
    }
    section#abtus h2.Choose {
        font-size: 25px !important;
        line-height: 35px;
        margin-bottom: 24px;
    }
    .signle-insurance {
        margin-bottom: 95px;
    }
    .newfeatuer {
        padding: 20px;
        background: #fff;
        height: unset;
    }
    .mypolicyfeat {
        background-color: #eee;
        margin-left: 0px;
        padding: 30px 10px 0px;
    }
    span.ctabnrtwo {
     font-size: 16px;
    padding-left: 0px;
    }
    span.ctabnrone {
        color: #fff;
        font-size: 17px;
        margin-right: 0px;
    }
    .ctabnr {
        background: #201d1d;
        text-align: left;
        margin-left: 0px;
        padding: 20px 20px;
    }
    .bnrwhitebox {
     margin-left: 0px;
     position: relative;}

     section.myhome.bg-overlay {
        padding: 145px 0px 60px 0;
    }

    section.myhome.bg-overlay h1 {
        font-size: 45px;
        line-height: 55px;
    }
    .headercnt .box-body p a {
        color: #fff;
        font-size: 15px;
    }
    .chatmsg {
     width: 56px;
    line-height: 53px;
    height: 54px;
    }
    .ltn__header-5 .ltn__header-options {
        margin-left: 213px;
    }
}
@media (max-width: 1240px) and (min-width: 1200px){
    section.Newleadform iframe {
        width: 440px;
        height: 440px;
    }
    .headercnt .box-body p a {
        color: #fff;
        font-size: 16px;}
        .exploring {
            margin-top: 0px;
        }
        .bnrwhitebox2.text-center #msform .action-button-previous {
            left: 38%;
      }
        section.myhome.bg-overlay2 h1 {
            font-size: 45px;
            line-height: 55px;
        }
        .header-bottom .menu-area .menu>ul>li>a {
            color: #101115;
            font-size: 15px;
            font-weight: 700;
            padding: 15px 16px;
            text-transform: capitalize;
        }
        .newbnr h2 {
            font-size: 44px;
            line-height: 43px;
        }
        .imge-box {
            margin-left: 0px;
          }
          .site-logo.go-top img {
            width: 37%;
        }
          section.Newleadform .Newleadform-outer-box .mainform .mynewform form#msform input#submit {
          margin: 0px 0px 0px 162px;
        }
          section.Newleadform #msform .action-button-previous {
            left: 36%;
                }
          .Planningfeatures .financial-box {
            height: 320px;}
          img.finiclapln {
 
            width: 100%;}
        section.Financialallleadform .fromback {
            margin-left: 10px;
            background: #efefef;
            padding: 30px 30px;
            border: 1px solid #c7c4c4;
        }
          .calling-dtail a {
            font-size: 18px;
        }
          section.contactdetail .fromback {
            margin-left: 20px;
            background: #efefef;
            padding: 30px 30px;}
          div#PascalCase {
            margin-top: 0px;
        }
          img.newagent {
            width: 18%;
            margin-top: -84px;
        }
        section.policy-faqs .accordion-button {
            padding: 10px 20px;}

        section.InsuranceSimplified {
            padding-top: 50px;
        }
        section.business .item {
            height: 225px;}
            .headercnt {
                background-color: #201d1d;
                padding: 18px 25px;
                margin-top: -16px;
            }
        .main-incididunt {
            margin-top: 50px;
            margin-right: 20px;
            position: relative;
        }
        .incididunt::before {
            content: '1';
            position: absolute;
            left: 18px;
            font-size: 75px;}

            .incididunt1::before {
                content: '2';
                position: absolute;
                font-size: 75px;
                left: 18px;}

                .incididunt2::before {
                    content: '3';
                    position: absolute;
                    left: 18px;
                    font-size: 75px;
                }
        .rolbox-01 {
         padding: 20px 40px 20px 40px;
            display: block;}
        h2.Choose {
            font-size: 27px;
            text-align: center;
            line-height: 37px;
        }
        img.agentteam {
            position: absolute;
            right: 0;
            bottom: 0px;
            max-width: 25%;
        }
        .signle-insurance h3 {
            font-size: 22px;
        }
        section.myhome.bg-overlay {
            padding: 180px 0px 80px 0px;
        }
            span.ctabnrone {
                color: #fff;
                font-size: 16px;
                margin-right: 3px;
            }  
            span.ctabnrtwo {
                color: #ffda47;
                font-size: 16px;
            }           
   section.myhome h1 {
     color: #201d1d;
     font-weight: 900;
     font-size: 48px;
    line-height: 58px;
    position: relative;
            }

    }
  
@media (max-width: 1399px) and (min-width: 1241px){
    section.Newleadform iframe {
        width: 440px;
        height: 440px;
    }
    .headercnt .box-body p a {
        color: #fff;
        font-size: 17px;}
        .exploring {
            margin-top: 0px;
        }
        .bnrwhitebox2.text-center #msform .action-button-previous {
            left: 40%;
      }
        .newbnr h2 {
            font-size: 44px;
            line-height: 43px;
        }
        section.formcontact h3 {
            font-size: 18px;
            line-height: 10px;
        }
        section.myhome.bg-overlay span.ctabnrtwo {
            color: #ffda47;
            font-size: 18px;
        }
        .headercnt {
            background-color: #201d1d;
            padding: 18px 20px;
            margin-top: -15px;
        }
        section.Newleadform .Newleadform-outer-box .mainform .mynewform form#msform input#submit {
            margin: 0px 0px 0px 162px;
          }
            section.Newleadform #msform .action-button-previous {
              left: 36%;}
        img.finiclapln {
        
            width: 100%;}
        section.Financialallleadform .fromback {
            margin-left: 10px;
            background: #efefef;
            padding: 30px 30px;
            border: 1px solid #c7c4c4;
        }
        section.myhome.bg-overlay span.ctabnrone {
            color: #fff;
            font-size: 18px;
            margin-right: 3px;
        }
        section.InsuranceSimplified {
            padding-top: 50px;
        }
        .calling-dtail a {
            font-size: 18px;
        }
          section.contactdetail .fromback {
            margin-left: 20px;
            background: #efefef;
            padding: 30px 30px;}
          div#PascalCase {
            margin-top: 0px;
        }
       
        img.newagent {
            width: 17%;
            margin-top: -76px;
        }
        .imge-box {
            margin-left: 0px;
          }
        .main-incididunt {
            margin-top: 50px;
            margin-right: 20px;
            position: relative;
        }
        .incididunt::before {
            content: '1';
            position: absolute;
            left: 18px;
            font-size: 75px;}

            .incididunt1::before {
                content: '2';
                position: absolute;
                font-size: 75px;
                left: 18px;}

                .incididunt2::before {
                    content: '3';
                    position: absolute;
                    left: 18px;
                    font-size: 75px;
                }
        .rolbox-01 {
         padding: 20px 40px 20px 40px;
            display: block;}
        h2.Choose {
            font-size: 27px;
            text-align: center;
            line-height: 37px;
        }
        img.agentteam {
            position: absolute;
            right: 0;
            bottom: 0px;
            max-width: 25%;
        }
        .signle-insurance h3 {
            font-size: 22px;
        }
        section.myhome.bg-overlay {
            padding: 100px 0px 80px 0px;}
            span.ctabnrone {
                color: #fff;
                font-size: 16px;
                margin-right: 3px;
            }  
            span.ctabnrtwo {
                color: #ffda47;
                font-size: 16px;
            }           
   section.myhome h1 {
     color: #201d1d;
     font-weight: 900;
     font-size: 48px;
    line-height: 58px;
    position: relative;
            }

}
@media (max-width: 1440px) and (min-width: 1400px){
    section.Newleadform iframe {
        width: 440px;
        height: 440px;
    }
    .headercnt .box-body p a {
        color: #fff;
        font-size: 17px;}
        .exploring {
            margin-top: 42px;
            margin-right: 50px;
        }
      
        section.formcontact h3 {
            font-size: 18px;
            line-height: 10px;
        }
        .newbnr h2 {
            font-size: 44px;
            line-height: 43px;
        }
        .rolbox-01 .btn-wrapper {
            display: block;
            margin-top: 30px;
            display: none;
        }
        section.business .item {
          height: 333px;}

        .headercnt {
        background-color: #201d1d;
            padding: 18px 22px;}
        section#abtus .Journey {
            height: 350px;}
        .calling-dtail a {
            font-size: 18px;
        }
        .site-logo.go-top img {
            width: 32%;
        }
        section.Newleadform .Newleadform-outer-box .mainform .mynewform form#msform input#submit {
            margin: 0px 0px 0px 162px;
          }
            section.Newleadform #msform .action-button-previous {
              left: 36%;}
        .Planningfeatures .financial-box {
         height: 400px;}
        .Planningfeatures .Management {
            font-size: 18px;
        }
        section.Financialallleadform .fromback {
            margin-left: 0px;}
        section.myhome.bg-overlay .ctabnr {
            margin-left: 0px;
            position: relative;
        }
        section.myhome.bg-overlay .ctabnr br{
            display: none;
        }
        .bnrwhitebox {
         margin-left: 0px;
         position: relative;}
       
        .fromback label {
            font-size: 14px;
        }
        section.contactdetail .fromback form input.form-control {
             height: 40px;}
        section.contactdetail .fromback {
            margin-left: 40px;}

    .imge-box {
       margin-left: 80px;
        }
        img.newagent {
            width: 16%;
            margin-top: -65px;}
        section.lifeinsurance-features {
            margin-top: -69px;
            padding-top: 100px;}
        section.serivceform {
            padding-top: 100px;
            padding-bottom: 100px;}
        section.DIfferent-types {
            padding-top: 80px;
            padding-bottom: 50px;
        }
        section.centersec {
         background-position: bottom;
        }
        .ltn__main-menu > ul > li {
            display: inline-block;
            position: relative;
            margin-right: 5px !important;
        }
        .headercnt {
        background-color: #201d1d;
            padding: 18px 10px;}
        .find-lead #msform .action-button-previous {
          left: 16%;
        }
        .Management{
            font-size: 20px;
        }
        .financial-box {
            height: 265px;}
        img.singleman {
            margin-top: -40px;
            margin-bottom: 15px;
            width: 48%;
        }
        .signle-insurance h3 {
            margin-bottom: 39px;
            margin-top: 34px;
            color: #000;
            font-size: 20px;
        }
        .ctabnr {
         margin-left: 0px;}

        .bnrwhitebox {
         margin-left: 0px;
         position: relative;}

        section.lifeinsurance-features li {
            display: inline-block;
            border: 1px solid #696565;
            bottom: -1px;
            position: relative;
            border-radius: 10px;
            font-size: 17px;
            color: #000;
            line-height: 24px;
            font-weight: 700;
            list-style: none;
            margin-right: 10px;
            padding: 26px 12px;
            cursor: pointer;
            width: 192px;
            background-repeat: no-repeat;
            background-size: cover;
            background-image: url(../images/insurance/formbg.jpg) !important;
        }
        section.myhome h1 {
            color: #201d1d;
            font-weight: 900;
            font-size: 43px;
            line-height: 55px;
            position: relative;
        }
        section.myhome.bg-overlay {
            padding: 180px 0px 100px 0px;
        }   
        span.ctabnrtwo a {
            color: #ffda47;
            font-size: 18px;
        }  
        span.ctabnrone {
            color: #fff;
            font-size: 18px;
            margin-right: 0px;
        }
        .signle-insurance h3 {
            font-size: 22px;
        }
        .rolbox-01 {
         padding: 22px 40px 22px 40px;}
         .main-incididunt {
            margin-top: 50px;
            position: relative;
            margin-right: 25px;
        }
        section.business {
         background-position: bottom;
        }
        }

@media (max-width: 1600px) and (min-width: 1441px){
    img.newagent {
        width: 16%;
        margin-top: -94px;
    } 
    section.Newleadform .Newleadform-outer-box .mainform .mynewform form#msform input#submit {
        margin: 0px 0px 0px 162px;
      }
        section.Newleadform #msform .action-button-previous {
          left: 37%;}
    section.Financialallleadform .fromback {
    margin-left: 0px;}
    section.myhome.bg-overlay .ctabnr {
        margin-left: 72px;
        position: relative;
    } 
    .bnrwhitebox {
     margin-left: 72px;
     position: relative;}
    .calling-dtail a {
        font-size: 18px;
    }
    .fromback label {
        font-size: 14px;
    }
    section.contactdetail .fromback form input.form-control {
         height: 40px;}
    section.contactdetail .fromback {
        margin-left: 40px;} 
    .headercnt .box-body p a {
        color: #fff;
        font-size: 18px;}
    .headercnt {
    background-color: #201d1d;
        padding: 18px 20px;} 
    section.myhome.bg-overlay {
          padding: 140px 0px 100px 0;
         }
         section.serivceform {
            padding-bottom: 80px;
        }
        section.lifeinsurance-features {
            margin-top: -72px;
            padding-top: 100px;
}
}

@media (max-width: 1919px) and (min-width: 1600px){
.ctabnr br {
    display: none;
}
section.Newleadform .Newleadform-outer-box .mainform .mynewform form#msform input#submit {
    margin: 0px 0px 0px 162px;
  }
   
}